import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";
var useNotificationSettings = function useNotificationSettings(isOpen) {
  return useQuery(["notificationSettings", isOpen], function () {
    return servicesUtils.getService("/profile/notifications/");
  }, {
    enabled: isOpen,
    staleTime: 0
  });
};
export default useNotificationSettings;