import { get, isEmpty } from "lodash";
import { useStoreState } from "pullstate";
import { useEffect, useState } from "react";
import Store from "../../utils/Store";
import Button from "../common/Button";
import Modal from "../common/Modal";
// import LeftMenu from "./LeftMenu";
import PatientWorklistMenu from "./MenuScreens/PatientWorklistMenu";
import { postSettings, validateUserConfig } from "./utils";
import Toast from "../Toast";
import classNames from "classnames";
import { getUserSettings } from "../../utils";
import { useTranslation } from "react-i18next";

const Settings = () => {
  const { t } = useTranslation();
  const [userSettingsConf, setUserSettingsConf] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [initialUserSettingsConf, setInitialUserSettingsConf] = useState(null);
  const [labelMappings, setLabelMappings] = useState({});
  const [tagsList, setTagsList] = useState();
  const [currentSettingsMenu, setCurrentSettingsMenu] = useState(0);

  const { isVisible, appConfig, userSettings } = useStoreState(Store, (s) => ({
    isVisible: s.isSettingsModalOpen,
    appConfig: s.appConfig,
    userSettings: s.userSettings,
  }));

  useEffect(() => {
    let _settings = { worklist: { tags: get(appConfig, ["tags_preference"], {}) } };
    const mapping = get(appConfig, ["mapping"], {});
    const tags_list = get(appConfig, ["tags_list"], {});

    if (!isEmpty(userSettings)) {
      _settings = get(userSettings, ["settings"], {});
    }
    setUserSettingsConf(_settings);
    setInitialUserSettingsConf(_settings);
    setLabelMappings(mapping);
    setTagsList(tags_list);
    setIsLoading(false);
  }, [isVisible, appConfig, userSettings]);

  const onClose = () => {
    Store.update((s) => {
      s.isSettingsModalOpen = false;
    });
  };

  // const changeSettingsMenu = (changedMenu) => {
  //   setCurrentSettingsMenu(changedMenu);
  // };

  const saveSettings = async () => {
    setIsLoading(true);
    const isValid = validateUserConfig(userSettingsConf);

    if (isValid) {
      try {
        await postSettings(userSettingsConf);
        Toast.success(t("settings_saved.toast.success"));
      } catch (error) {
        Toast.error(t("settings_saved.toast.error"));
      }
    } else {
      Toast.error(t("invalid_configuration"));
    }
    try {
      await getUserSettings();
    } catch (error) {
      console.error(error);
      Toast.error("settings_saved.toast.loading_error");
    }
    setIsLoading(false);
  };

  return (
    <Modal
      show={isVisible}
      onClose={onClose}
      title={t("settings_key")}
      className="w-full sm:w-6/12 !bg-gray-100 dark:!bg-gray-800 sm:h-max"
      closeOnClickAway
    >
      <div className="bg-gray-100 dark:bg-gray-800 rounded-b-md select-none relative">
        <div className="grid grid-cols-12">
          {/*<LeftMenu
            changeSettingsMenu={changeSettingsMenu}
            currentSettingsMenu={currentSettingsMenu}
          />*/}
          <div className="col-span-12 dark:border-r-0 dark:border-t-0 dark:border-b-0 dark:border-l-gray-600 p-3 sm:p-5 h-[500px]">
            {currentSettingsMenu === 0 && (
              <PatientWorklistMenu
                userSettingsConf={userSettingsConf}
                setUserSettingsConf={setUserSettingsConf}
                initialUserSettingsConf={initialUserSettingsConf}
                labelMappings={labelMappings}
                tagsList={tagsList}
              />
            )}
          </div>
        </div>
        <div className="flex items-center justify-end space-x-5 mt-5 p-5 border-t border-t-gray-300 dark:border-t-gray-600">
          <Button className="w-36" onClick={onClose}>
            {t("button.cancel")}
          </Button>
          <Button isPrimary className="w-36" onClick={saveSettings}>
            {t("button.save_changes")}
          </Button>
        </div>
      </div>
      <div
        className={classNames(
          "absolute w-full h-full visible bg-gray-600 bg-opacity-50 top-0 left-0 rounded-md text-gray-900 flex items-center justify-center z-[20]",
          {
            visible: isLoading,
            invisible: !isLoading,
          }
        )}
      >
        {t("loading_key")}...
      </div>
    </Modal>
  );
};

export default Settings;
