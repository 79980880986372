import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useGetUserGroups = (isOpen) => {
  return useQuery(
    ["userGroups", isOpen],
    () => {
      return servicesUtils.getService("/accounts/groups/");
    },
    {
      enabled: isOpen,
    }
  );
};

export default useGetUserGroups;
