import classNames from "classnames";
import { get, isEmpty } from "lodash";

const Tag = ({
  tagNo,
  userSettingsConf,
  studyName,
  initialUserSettingsConf,
  labelMappings,
  tagsList,
}) => {
  const {
    category,
    value: value_,
    use_default,
  } = userSettingsConf.worklist.tags[studyName][tagNo];

  let value;
  const isValueObject = category === "risk_groups" || category === "symptoms";
  if (isValueObject) {
    get(tagsList, ["qxr", category, category])?.map((choiceObj) => {
      if (choiceObj.id === value_) {
        value = choiceObj.choice;
      }
    });
  } else {
    value = value_;
  }

  return (
    <div className="w-full grid grid-cols-12 rounded-md px-5 py-4 cursor-pointer">
      <div className="col-span-3 font-semibold text-left flex items-center text-[12px]">
        {tagNo === "tag1" ? "TAG 01" : tagNo === "tag2" ? "TAG 02" : "TAG 03"}
      </div>
      <div className="col-span-8 text-left text-[12px] flex items-center">
        {isEmpty(category) && isEmpty(value) ? (
          <span className="bg-gray-100 dark:bg-gray-600 border border-gray-400 text-gray-900 dark:text-gray-100 rounded-full px-4 py-1 cursor-pointer">
            Not Selected
          </span>
        ) : use_default ? (
          <span className="px-4">Default</span>
        ) : !category || !value ? (
          <span className="text-red-400">Invalid configuration</span>
        ) : (
          <span className="bg-gray-200 dark:bg-gray-700 border border-gray-400 text-gray-900 dark:text-gray-100 rounded-full px-4 py-1 cursor-pointer">
            {labelMappings[value] || value}
          </span>
        )}
        <span
          className={classNames("invisible ml-2", {
            "text-red-400 !visible":
              (isValueObject
                ? initialUserSettingsConf.worklist.tags[studyName][tagNo].value !== value_
                : initialUserSettingsConf.worklist.tags[studyName][tagNo].value !==
                  value) ||
              initialUserSettingsConf.worklist.tags[studyName][tagNo].category !==
                category,
          })}
        >
          *
        </span>
      </div>
      <div className="col-span-1 flex items-center justify-end">
        <svg
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          className="stroke-gray-900 dark:stroke-gray-200"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 10L12 14L16 10"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default Tag;
