import React from "react";
import DatePicker from "react-datepicker";
import classNames from "classnames";
import "react-datepicker/dist/react-datepicker.css";
import { faChevronLeft, faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import AwesomeIcon from "../AwesomeIcon";

const DateTimeInput = ({
  label,
  value,
  onChange,
  id,
  dateFormat,
  datePlaceholder,
  timeFormat,
  timePlaceholder,
  className,
  required,
  unit,
  disabled,
  isClinicalField,
  wrapperClassName,
  inputClassName,
  showTimeFirst,
  hideTime,
}) => {
  const onChangeDateTme = (dateTime, isDate, step) => {
    let _value = null;
    if (dateTime) {
      if (!!value) {
        _value = new Date(value);
        let newDateTime = new Date(dateTime);
        if (step === "next") {
          newDateTime.setDate(newDateTime.getDate() + 1);
        } else if (step === "prev") {
          newDateTime.setDate(newDateTime.getDate() - 1);
        }

        if (isDate) {
          _value.setDate(newDateTime.getDate());
          _value.setMonth(newDateTime.getMonth());
          _value.setFullYear(newDateTime.getFullYear());
        } else {
          _value.setHours(newDateTime.getHours());
          _value.setMinutes(newDateTime.getMinutes());
        }
      } else {
        _value = new Date(dateTime);
      }
    }
    onChange(_value);
  };
  return (
    <div
      className={classNames(
        "relative rounded-md px-2 max-w-lg text-gray-900 dark:text-gray-100",
        {
          "border border-gray-500": !isClinicalField,
          "opacity-50": disabled,
          [wrapperClassName]: !!wrapperClassName,
        }
      )}
    >
      <label
        className={classNames("text-md", {
          "bg-gray-800 absolute left-4 -top-3 px-4": !isClinicalField,
          "bg-transparent flex mb-5 text-base": isClinicalField,
        })}
      >
        {label} {!!required && <span className="text-red-500">*</span>}
      </label>
      <div
        className={classNames("flex items-center gap-3 sm:gap-6", {
          "flex-row-reverse": showTimeFirst,
        })}
      >
        <div
          className={classNames("flex grow items-center gap-3", { "grow-0": hideTime })}
        >
          <button
            className={classNames("h-[39px] w-[39px] dark:bg-gray-800 rounded-6 p-2", {
              "opacity-50 cursor-not-allowed": !value,
            })}
            onClick={(e) => {
              onChangeDateTme(value, true, "prev");
              e.preventDefault();
            }}
          >
            <AwesomeIcon name={faChevronLeft} />
          </button>
          <div
            className={classNames("flex items-center grow rounded", {
              "bg-gray-100 border border-gray-300 dark:border-none dark:bg-gray-800 py-1 px-3":
                isClinicalField,
              [className]: !!className,
            })}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              className="fill-gray-600 dark:fill-gray-100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.825 16.25C8.14167 16.25 7.56667 16.0167 7.1 15.55C6.63333 15.0833 6.4 14.5083 6.4 13.825C6.4 13.1417 6.63333 12.5667 7.1 12.1C7.56667 11.6333 8.14167 11.4 8.825 11.4C9.50833 11.4 10.0833 11.6333 10.55 12.1C11.0167 12.5667 11.25 13.1417 11.25 13.825C11.25 14.5083 11.0167 15.0833 10.55 15.55C10.0833 16.0167 9.50833 16.25 8.825 16.25ZM4.5 22C4.1 22 3.75 21.85 3.45 21.55C3.15 21.25 3 20.9 3 20.5V5C3 4.6 3.15 4.25 3.45 3.95C3.75 3.65 4.1 3.5 4.5 3.5H6.125V2H7.75V3.5H16.25V2H17.875V3.5H19.5C19.9 3.5 20.25 3.65 20.55 3.95C20.85 4.25 21 4.6 21 5V20.5C21 20.9 20.85 21.25 20.55 21.55C20.25 21.85 19.9 22 19.5 22H4.5ZM4.5 20.5H19.5V9.75H4.5V20.5ZM4.5 8.25H19.5V5H4.5V8.25ZM4.5 8.25V5V8.25Z" />
            </svg>
            <DatePicker
              id={id}
              selected={value ? new Date(value) : value}
              onChange={(val) => onChangeDateTme(val, true)}
              placeholderText={datePlaceholder || dateFormat}
              required={required}
              dateFormat={dateFormat}
              disabled={disabled}
              className={classNames("flex grow bg-transparent w-full border-0 pb-3", {
                "text-center !p-2 mb-1": isClinicalField,
                [inputClassName]: !!inputClassName,
              })}
            />
          </div>
          <button
            className={classNames(
              "h-[39px] w-[39px] dark:bg-gray-800 rounded-6 p-2 cursor-pointer",
              {
                "opacity-50 cursor-not-allowed": !value,
              }
            )}
            onClick={(e) => {
              onChangeDateTme(value, true, "next");
              e.preventDefault();
            }}
          >
            <AwesomeIcon name={faChevronRight} />
          </button>
        </div>
        <div
          className={classNames("flex items-center grow rounded w-1/2 sm:w-auto", {
            "bg-gray-100 border border-gray-300 dark:border-none dark:bg-gray-800 py-1 px-3":
              isClinicalField,
            [className]: !!className,
            hidden: hideTime,
          })}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            className="fill-gray-600 dark:fill-gray-100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.675 16.825L16.8 15.7L12.825 11.7V6.675H11.325V12.3L15.675 16.825ZM12 22C10.6333 22 9.34167 21.7375 8.125 21.2125C6.90833 20.6875 5.84583 19.9708 4.9375 19.0625C4.02917 18.1542 3.3125 17.0917 2.7875 15.875C2.2625 14.6583 2 13.3667 2 12C2 10.6333 2.2625 9.34167 2.7875 8.125C3.3125 6.90833 4.02917 5.84583 4.9375 4.9375C5.84583 4.02917 6.90833 3.3125 8.125 2.7875C9.34167 2.2625 10.6333 2 12 2C13.3667 2 14.6583 2.2625 15.875 2.7875C17.0917 3.3125 18.1542 4.02917 19.0625 4.9375C19.9708 5.84583 20.6875 6.90833 21.2125 8.125C21.7375 9.34167 22 10.6333 22 12C22 13.3667 21.7375 14.6583 21.2125 15.875C20.6875 17.0917 19.9708 18.1542 19.0625 19.0625C18.1542 19.9708 17.0917 20.6875 15.875 21.2125C14.6583 21.7375 13.3667 22 12 22ZM12 20.5C14.3333 20.5 16.3333 19.6667 18 18C19.6667 16.3333 20.5 14.3333 20.5 12C20.5 9.66667 19.6667 7.66667 18 6C16.3333 4.33333 14.3333 3.5 12 3.5C9.66667 3.5 7.66667 4.33333 6 6C4.33333 7.66667 3.5 9.66667 3.5 12C3.5 14.3333 4.33333 16.3333 6 18C7.66667 19.6667 9.66667 20.5 12 20.5Z" />
          </svg>

          <DatePicker
            selected={value ? new Date(value) : value}
            onChange={(val) => onChangeDateTme(val, false)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={5}
            timeCaption="Time"
            dateFormat={timeFormat}
            placeholderText={timePlaceholder || timeFormat}
            calendarClassName="flex items-center justify-center"
            className={classNames("block bg-transparent w-full border-0 pb-3", {
              "text-center !p-2 mb-1": isClinicalField,
              [inputClassName]: !!inputClassName,
            })}
          />
        </div>
        {!!unit && <span className="text-md px-3 text-gray-400">{unit}</span>}
      </div>
    </div>
  );
};

export default DateTimeInput;
