import Store from "../../utils/Store";
import Modal from "../common/Modal";
import { clone, get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import Button from "../common/Button";
import TextInput from "../FormComponents/TextInput";
import Toast from "../Toast";
import useSaveGroup from "./services/useSaveGroup";
import useAddUsers from "./services/useAddUsers";
import useRemoveUsers from "./services/useRemoveUsers";
import useUpdateGroup from "./services/useUpdateGroup";
import classNames from "classnames";
import useDeleteGroup from "./services/useDeleteGroup";
import useLeaveGroup from "./services/useLeaveGroup";
import InviteUsers from "../InviteUsers";
import UserRow from "./UserRow";
import AwesomeIcon from "../AwesomeIcon";
import { faTrash, faXmark } from "@fortawesome/pro-regular-svg-icons";
import DeleteModal from "../AutomatedShare/DeleteModal";
import {
  customRegexValidation,
  startCharCheckValidation,
  validateFields,
} from "../../utils/regexValidation";
import { useTranslation } from "react-i18next";

const CreateUserModal = ({ selectedGroup, isVisible, onClose }) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [collaboratorsList, setCollaboratorsList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [error, setError] = useState("");
  const isAdmin = get(selectedGroup, ["group_admin"], false);

  const saveGroup = useSaveGroup();
  const addUsers = useAddUsers();
  const removeUsers = useRemoveUsers();
  const updateGroup = useUpdateGroup();
  const deleteGroup = useDeleteGroup();
  const leaveGroup = useLeaveGroup();

  const isEdit = !isEmpty(selectedGroup);
  const { email: usersEmail } = Store.useState((s) => s.user);
  const removedUsers = collaboratorsList
    .filter((user) => user.saved && !user.selected)
    .map((user) => user.email);

  const addedUsers = collaboratorsList
    .filter((user) => !user.saved && user.selected)
    .map((user) => user.email);

  const disableSave =
    (isEmpty(removedUsers) &&
      isEmpty(addedUsers) &&
      name === selectedGroup?.name &&
      description === selectedGroup?.description) ||
    isEmpty(name) ||
    (!isEdit && isEmpty(addedUsers));

  useEffect(() => {
    if (!isEmpty(selectedGroup)) {
      let defaultUsers = get(selectedGroup, ["users"], []).map((user) => ({
        ...user,
        selected: true,
        saved: true,
      }));
      setCollaboratorsList(defaultUsers);
      setName(selectedGroup?.name);
      setDescription(selectedGroup?.description);
    }
  }, [selectedGroup]);

  const onCloseModal = (updated) => {
    setName("");
    setDescription("");
    setCollaboratorsList([]);
    onClose(updated);
    setError();
  };

  const validateForm = () => {
    if (isEmpty(name) || (!isEdit && isEmpty(addedUsers))) {
      return false;
    }
    return true;
  };

  const onSave = async () => {
    const validationResults = validateFields([
      {
        fn: customRegexValidation,
        value: name,
        err: ` ${t("group.validation.name_special_char")},`,
      },
      {
        fn: startCharCheckValidation,
        value: name,
        err: ` ${t("group.validation.name_start")},`,
      },
      {
        fn: customRegexValidation,
        value: description,
        err: ` ${t("group.validation.description_special_char")},`,
      },
      {
        fn: startCharCheckValidation,
        value: description,
        err: ` ${t("group.validation.description_start")},`,
      },
    ]);
    if (validationResults) {
      setError(validationResults);
    }
    try {
      if (validateForm() && !validationResults) {
        let response = null;
        if (isEdit) {
          const payload = {
            id: get(selectedGroup, ["id"], ""),
          };
          if (!isEmpty(addedUsers)) {
            // add users
            payload["emails"] = addedUsers;
            response = await addUsers.mutateAsync(payload);
          }
          if (!isEmpty(removedUsers)) {
            // remove users
            payload["emails"] = removedUsers;
            await removeUsers.mutateAsync(payload);
          }
          if (name !== selectedGroup.name || description !== selectedGroup.description) {
            // update group
            payload["description"] = description;
            payload["name"] = name;
            await updateGroup.mutateAsync(payload);
          }
        } else {
          const payload = {
            name: name,
            description: description,
            emails: addedUsers,
          };
          await saveGroup.mutateAsync(payload);
        }
        let message = get(response, ["message"], []);
        if (!isEmpty(message) && message.length > 1) {
          let warning = message[0];
          message.shift();
          if (message) {
            warning += message.join(", ");
          }
          Toast.warning(warning);
        }
        Toast.success(t("group.toast.saved_successfully"));
        onCloseModal(true);
      }
    } catch (err) {
      const status = get(err, ["response", "status"], 0);
      if (status && (status === 403 || status === 406)) {
        const errorMsg = get(err, ["response", "data", "message"], "");
        Toast.error(errorMsg);
      } else {
        Toast.error(t("group.toast.saved_failed"));
      }
    }
  };

  const onDelete = async () => {
    try {
      const payload = {
        id: get(selectedGroup, ["id"], ""),
      };
      await deleteGroup.mutateAsync(payload);
      Toast.success(t("group.toast.deleted_successfully"));
      onCloseModal(true);
      setDeleteModal(false);
    } catch (err) {
      const status = get(err, ["response", "status"], 0);
      if (status && status === 403) {
        const errorMsg = get(err, ["response", "data", "message"], "");
        Toast.error(errorMsg);
      } else {
        Toast.error(t("group.toast.deleted_failed"));
      }
      setDeleteModal(false);
    }
    deleteGroup;
  };

  const onExitGroup = async () => {
    try {
      const payload = {
        id: get(selectedGroup, ["id"], ""),
      };
      await leaveGroup.mutateAsync(payload);
      Toast.success(t("group.toast.exited_successfully"));
      onCloseModal(true);
    } catch (err) {
      const status = get(err, ["response", "status"], 0);
      if (status && status === 406) {
        const errorMsg = get(err, ["response", "data", "message"], "");
        Toast.error(errorMsg);
      } else {
        Toast.error(t("group.toast.exited_failed"));
      }
    }
  };

  const onToggleCollaborator = ({ email }, update) => {
    const updatedList = collaboratorsList.map((item) => {
      if (item.email === email) {
        if (update.delete) {
          item.selected = !item.selected;
        }
        return item;
      }
      return item;
    });
    setCollaboratorsList(updatedList);
  };

  const onAddNewCollabrators = (_collaborators) => {
    const _collaboratorsList = clone(collaboratorsList);

    _collaborators.forEach((_collaborator) => {
      if (!_collaboratorsList.some((c) => c.email === _collaborator.value)) {
        _collaboratorsList.unshift({
          name: _collaborator.text,
          email: _collaborator.value,
          selected: true,
          saved: false,
        });
      }
    });

    setCollaboratorsList(_collaboratorsList);
  };

  return (
    <>
      <Modal
        title={`${isEdit ? t("edit_group_title") : t("create_group_title")}`}
        onClose={onCloseModal}
        show={isVisible}
        className="flex flex-col w-full h-[90%] sm:w-7/12 md:w-7/12 lg:w-5/12 sm:h-5/6 sm:!max-h-[80%] overflow-hidden"
      >
        <div className="flex flex-col h-full mt-4 text-left overflow-y-auto scrollbar-light dark:scrollbar">
          <div className="mx-5">
            <form>
              <TextInput
                label={t("user_group.name_label")}
                placeholder={t("user_group.name_placeholder")}
                name="groupName"
                id="groupName"
                wrapperClassName="my-5"
                required
                value={name}
                onChange={(val) => setName(val)}
              />
              <TextInput
                label={t("user_group.description_label")}
                placeholder={t("user_group.description_placeholder")}
                name="groupDescription"
                id="groupDescription"
                wrapperClassName="my-5"
                value={description}
                onChange={(val) => setDescription(val)}
              />
            </form>
          </div>
          <div className="flex flex-col h-full mx-5 space-y-4">
            <p className="text-base">{t("user_group.add_members")}</p>
            <InviteUsers onSubmit={onAddNewCollabrators} />
            <div className="bg-gray-300 dark:bg-gray-900 py-2 rounded-lg">
              <div className="max-h-64 sm:max-h-56 overflow-y-auto scrollbar-light dark:scrollbar sm:!scrollbar-group divide-y divide-gray-800">
                {!isEmpty(collaboratorsList) &&
                  collaboratorsList.map((user, index) => {
                    const { email } = user;
                    const disabled = (isEdit && !isAdmin) || usersEmail === email;
                    return (
                      <UserRow
                        key={email}
                        user={user}
                        index={index}
                        disabled={disabled}
                        onChange={onToggleCollaborator}
                      />
                    );
                  })}
              </div>
              {isEmpty(collaboratorsList) && (
                <p className="flex flex-col justify-center items-center text-center w-full h-full text-gray-600 cursor-default">
                  {t("user_group.please_add_members")}
                </p>
              )}
            </div>
          </div>
          {error && (
            <p className="text-red-500 px-6 py-1 text-left mb-4 sm:mb-0">{error}</p>
          )}
          <div className="align-bottom border-t h-fit border-gray-800 w-full mt-4">
            <div className="flex items-center justify-between flex-row p-4 border-t border-t-gray-600">
              {isEdit && !isAdmin ? (
                <div className="p-4 flex items-center justify-center w-full">
                  <div
                    className="flex items-center justify-center cursor-pointer"
                    onClick={onExitGroup}
                  >
                    <AwesomeIcon name={faXmark} className="w-6" />
                    <span className="pl-2">{t("user_group.exit")}</span>
                  </div>
                </div>
              ) : (
                <>
                  <div>
                    <div
                      onClick={() => setDeleteModal(true)}
                      className={classNames("flex items-center cursor-pointer", {
                        hidden: !isAdmin,
                      })}
                    >
                      <AwesomeIcon
                        name={faTrash}
                        className="dark:text-gray-200 text-gray-700"
                      />
                      <span className="pl-2">{t("button.delete")}</span>
                    </div>
                  </div>
                  <div className="flex space-x-4 flex-6">
                    <Button
                      isSecondary
                      className="grow-1 w-24 lg:w-36"
                      onClick={onCloseModal}
                    >
                      {t("button.cancel")}
                    </Button>
                    <Button
                      isPrimary
                      isLoading={
                        saveGroup?.isLoading ||
                        addUsers?.isLoading ||
                        removeUsers?.isLoading ||
                        updateGroup?.isLoading
                      }
                      onClick={onSave}
                      className="sm:mt-0 grow-1 ml-4 w-24 lg:w-36"
                      disabled={disableSave}
                    >
                      {t("button.save")}
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <DeleteModal
        deleteModal={deleteModal}
        onDelete={onDelete}
        setDeleteModal={setDeleteModal}
        title={t("delete_user_group.title")}
        content={t("delete_user_group.text")}
      />
    </>
  );
};

CreateUserModal.defaultProps = {};

CreateUserModal.propTypes = {};

export default CreateUserModal;
