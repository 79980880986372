import { clone, debounce, get, isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { isValidEmail } from "../../utils";
import useGetUserGroups from "../UserGroupManagement/services/useGetUserGroups";
import InviteesList from "./inviteesList";
import SelectedTag from "./SelectedTag";
import useGetUsers from "./services/useGetUsers";
import { useTranslation } from "react-i18next";

const InviteUsers = ({ hasGroups, onChange, onSubmit, allowMultiple }) => {
  const { t } = useTranslation();
  const [text, setText] = useState("");
  const [invitees, setInvitees] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [open, setOpen] = useState(false);
  const [showControl, toggleShowControl] = useState(true);
  let timeout = null;

  const removeDuplicates = (list) => {
    return list.filter((value, index, self) => {
      return (
        index ===
        self.findIndex(
          (t) => t.email === value.email && t.id === value.id && t.value === value.value
        )
      );
    });
  };

  const { data: usersObj, isLoading: usersLoading } = useGetUsers(text);
  const { data: userGroupdData, isLoading: groupsLoading } = useGetUserGroups(
    !!hasGroups
  );
  const groups = get(userGroupdData, ["groups"], []);
  const users = get(usersObj, ["users"], []);
  const filteredGroups = groups.filter((g) =>
    g?.name?.toLowerCase()?.includes(text.toLocaleLowerCase())
  );

  let onChangeInput = useCallback(
    debounce((_text) => setText(_text), 500),
    []
  );

  const onChangeInvitees = (_invitees) => {
    setInvitees(_invitees);
    onChange(invitees);
    if (!allowMultiple) {
      submitField(_invitees);
    }
  };

  // hacks to solve issue: after selecting email from dropdown, input field is not getting cleared
  useEffect(() => {
    if (showControl) {
      timeout = setTimeout(() => {
        document.getElementById("emails-input").focus();
      }, 50);
    }
    return () => clearTimeout(timeout);
  }, [showControl]);

  const clearText = () => {
    setText("");
    toggleShowControl(false);
    timeout = setTimeout(() => {
      toggleShowControl(true);
    }, 0);
  };

  const onChangeText = (_text) => {
    setOpen(true);
    onChangeInput(_text);
    setInvalidEmails([]);
  };

  const onChangeEmail = (_emails) => {
    const _newInvitees = _emails.map((email) => {
      let _inviteeObj = invitees.find((i) => i.value === email);
      if (!_inviteeObj) {
        let userObj = users.find((u) => u.email === email);
        if (userObj) {
          _inviteeObj = {
            value: userObj.email,
            text: userObj.name,
            description: userObj.email,
          };
        } else {
          let groupObj = groups.find((g) => g.id === email);
          if (groupObj) {
            _inviteeObj = {
              value: groupObj.id,
              text: groupObj.name,
              description: `${groupObj.id} members`,
            };
          }
        }
      }
      return _inviteeObj;
    });
    onChangeInvitees(removeDuplicates(_newInvitees));
  };

  const Placeholder = () => {
    return (
      <span className="text-gray-600 font-normal text-[14px]">
        {t("share_placeholder")}
      </span>
    );
  };

  const onSelectInvitee = (_invitee) => {
    clearText();
    const _invitees = clone(invitees);
    _invitees.push(_invitee);
    onChangeInvitees(removeDuplicates(_invitees));
    setOpen(false);
  };

  const submitField = (_invitees = invitees) => {
    setOpen(false);
    setInvitees([]);
    onSubmit(_invitees);
  };

  const onKeyPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      submitField();
    }
  };

  const groupsList = filteredGroups.map((g) => ({
    value: g.id,
    text: g.name,
    description: `${get(g, ["users", "length"], "")} members`,
  }));
  const usersList = users.map((u) => ({
    value: u.email,
    text: u.name,
    description: u.email,
  }));
  const isOpen =
    open &&
    (usersLoading || groupsLoading || !isEmpty(usersList) || !isEmpty(groupsList));

  const inviteesList = invitees.map((inv) => inv.value);
  return (
    <div className="flex flex-col w-full">
      {isOpen && (
        <div
          className="absolute h-full w-full bg-gray-700 opacity-20 top-0 left-0"
          onClick={() => setOpen(false)}
        />
      )}
      <div className="relative flex flex-col w-full rounded-lg !bg-gray-300 dark:!bg-gray-900 px-3">
        <div className="realtive flex flex-col w-full pb-3 ">
          <div
            className="relative w-full rounded-lg !bg-gray-900"
            onClick={() => setOpen(true)}
          >
            {showControl && (
              <ReactMultiEmail
                placeholder={<Placeholder />}
                emails={inviteesList}
                id="emails-input"
                className="emails-input flex items-center justify-start !bg-gray-300 !text-gray-800 dark:!bg-gray-900 dark:!text-gray-200 w-full max-h-9 overflow-y-auto !border-0 !border-b hover:!border-teal-500 !rounded-none !rounded-t-lg !px-0 !pb-0"
                validateEmail={(email) => {
                  // using this as an "onChange" event and getting rid of old values
                  if (invalidEmails !== []) {
                    setInvalidEmails([]);
                  }

                  if (email === "undefined") {
                    return false;
                  }

                  let isValid = isValidEmail(email);

                  if (isValid) {
                    isValid = !isEmpty(users.find((u) => u.email === email));
                    if (!isValid) {
                      let _invalidEmails = clone(invalidEmails);
                      _invalidEmails.push(email);
                      setInvalidEmails(Array.from(new Set(_invalidEmails)));
                    }
                  }

                  return isValid;
                }}
                onChange={onChangeEmail}
                onChangeInput={onChangeText}
                onKeyDown={onKeyPress}
                getLabel={(email, index, removeEmail) => {
                  let inviteeObj = invitees.find((i) => i.value === email);
                  let label = get(inviteeObj, ["text"], "");
                  let isGroup = false;
                  if (hasGroups && email) {
                    const group = groups.find((g) => +g.id === +email);
                    isGroup = !isEmpty(group);
                  }

                  return (
                    <SelectedTag
                      label={label}
                      isGroup={isGroup}
                      key={index}
                      onRemove={() => removeEmail(index)}
                    />
                  );
                }}
              />
            )}

            {isOpen && (
              <div className="absolute z-200 bg-gray-200 dark:bg-gray-900 w-full rounded-b-lg border border-gray-200 dark:border-gray-800 max-h-60 overflow-y-auto shadow-2xl text-left">
                {/* users */}
                <InviteesList
                  list={usersList}
                  onSelect={onSelectInvitee}
                  isLoading={usersLoading}
                />
                {/* groups */}
                {hasGroups && (
                  <InviteesList
                    title="Groups"
                    list={groupsList}
                    onSelect={onSelectInvitee}
                    isLoading={groupsLoading}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {!isEmpty(invalidEmails) && (
        <div className="flex text-red-600 text-md px-3">
          {`${invalidEmails.join(",")} ${t("share_not_registered")}`}
        </div>
      )}
    </div>
  );
};

InviteUsers.defaultProps = {
  hasGroups: false,
  onChange: () => {},
  onSubmit: () => {},
  allowMultiple: false,
};

InviteUsers.propTypes = {
  hasGroups: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  allowMultiple: PropTypes.bool,
};

export default InviteUsers;
