import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useGetUsers = (search) => {
  return useQuery(
    ["users", search],
    () => {
      return servicesUtils.getService(`/accounts/users/?emails=${search}`);
    },
    {
      enabled: !!search && search.length > 1,
    }
  );
};

export default useGetUsers;
