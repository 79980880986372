import PropTypes from "prop-types";
import Modal from "../common/Modal";
import { useTranslation } from "react-i18next";
import Checkbox from "../common/Checkbox";

const FindingsTagModal = (props) => {
  const { showFindingModal, onCloseModal, onChangeFindings, findingDetails, name } =
    props;
  const { t } = useTranslation();

  return (
    <Modal
      title={t("setting_notification")}
      onClose={onCloseModal}
      show={showFindingModal}
      className="flex flex-col w-full sm:w-7/12 lg:w-6/12 xl:w-4/12 h-4/5 overflow-hidden"
    >
      <div className="p-6 overflow-y-auto scrollbar">
        <div className="pb-3">
          <div className="font-bold mb-2 text-left">{t("findings_modal_notify")}</div>
          <div className="text-gray-700 dark:text-gray-300 text-sm text-left">
            {t("findings_modal_info")}
          </div>
        </div>
        <div className="font-bold text-left py-3">{t(name)}</div>
        <div>
          {findingDetails?.map((detail, index) => (
            <div key={index}>
              <Checkbox
                id={t(detail?.name)}
                className="w-full py-3 pr-2"
                checked={detail?.enabled}
                onChange={() => {
                  onChangeFindings({ ...detail, enabled: !detail?.enabled }, index);
                }}
                label={t(detail.name)}
              />
              <div className="grid sm:grid-cols-2 ml-6 gap-y-2">
                {detail?.findings_list?.map((list, _index) => (
                  <div key={_index}>
                    <Checkbox
                      id={t(list?.name)}
                      className="w-full my-2 pr-2"
                      disabled={!detail.enabled}
                      checked={list?.enabled}
                      onChange={() => {
                        onChangeFindings(
                          { ...list, enabled: !list.enabled },
                          index,
                          _index
                        );
                      }}
                      label={t(list.name)}
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

FindingsTagModal.defaultProps = {
  onChange: () => {},
};

FindingsTagModal.propTypes = {
  onChange: PropTypes.func,
};

export default FindingsTagModal;
