import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { clone, get, isEmpty } from "lodash";
import ConditionsModal from "./ConditionsModal";
import Store from "../../utils/Store";
import useGetConditions from "./services/useGetConditions";
import useGetUserGroups from "../UserGroupManagement/services/useGetUserGroups";
import { useStoreState } from "pullstate";
import useWorkspacesData from "../WorkspaceManagementModal/services/useWorkspaceData";
import SelectInput from "../FormComponents/SelectInput";
import AwesomeIcon from "../AwesomeIcon";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { faPenToSquare } from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

export const findingSections = {
  qer: "automated_share.qer",
  qxr: "automated_share.qxr",
  qct: "automated_share.qct",
  qmsk: "automated_share.qmsk",
  forms: "automated_share.forms",
  emergency: "automated_share.emergency",
};

function AutomatedShare({ isVisible }) {
  const { t } = useTranslation();
  const [isEditConditionOpen, toggleEditCondition] = useState(false);
  const [currentCondition, setCurrentCondition] = useState({});
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState(0);
  const { data: conditionsData, refetch: refetchConditions } = useGetConditions(
    isVisible,
    selectedWorkspaceId
  );
  const { data: workspaceData } = useWorkspacesData(isVisible);
  const { data: userGroupdData, refetch: refetchUserGroups } =
    useGetUserGroups(isVisible);
  const groups = get(userGroupdData, ["groups"], []);

  const conditions = get(conditionsData, ["conditions"], []);
  const allFindings = get(conditionsData, ["default_false_keys"], {});
  const ownedWorkspaces = get(workspaceData, ["owned_workspaces"], []);
  const workspaceOptions = ownedWorkspaces.map((w) => ({
    id: w.id,
    label: w.name,
    value: w.id,
  }));

  useEffect(() => {
    const _selectedWorkspaceId = get(workspaceOptions, [0, "id"], 0);
    setSelectedWorkspaceId(_selectedWorkspaceId);
  }, [workspaceData]);

  const { isUserGroupOpen } = useStoreState(Store, (s) => ({
    isUserGroupOpen: s.isUserGroupOpen,
  }));

  useEffect(() => {
    if (isVisible && window.isUserGroupOpen && !isUserGroupOpen) {
      window.isUserGroupOpen = false;
      refetchUserGroups();
    }
    if (isUserGroupOpen) {
      window.isUserGroupOpen = true;
    }
  }, [isVisible, isUserGroupOpen]);

  const onCloseConditionModel = () => {
    toggleEditCondition(false);
    setCurrentCondition({});
    refetchConditions();
  };

  const getCriteria = (condition) => {
    let result = "";
    if (!isEmpty(condition)) {
      const categories = Object.keys(condition);
      const checkedCounts = {};
      categories.forEach((key) => {
        const findings = condition[key];
        const checkedFindings = Object.keys(findings).filter((f) => findings[f]);
        if (checkedFindings.length) {
          checkedCounts[key] = checkedFindings.length;
        }
      });

      Object.keys(checkedCounts).forEach((key, index) => {
        if (findingSections[key]) {
          result += `${checkedCounts[key]} ${t(findingSections[key])}`;
        }
      });
    }
    return result;
  };

  const getGroupNames = (condition) => {
    let result = "";
    const selectedGroups = get(condition, ["group"], []);
    selectedGroups.forEach((groupId, index) => {
      const groupObj = groups.find((g) => g.id === groupId);
      if (groupObj) {
        result += `${index != 0 ? "," : ""} ${groupObj?.name}`;
      }
    });
    return result;
  };

  return (
    <>
      <div className="m-4 flex flex-col text-left space-y-4 h-full sm:h-[420px]">
        <div>
          <p className="text-base">{t("automated_share.conditional")}</p>
          <p className="text-md text-gray-800 dark:text-gray-300">
            {t("automated_share.text")}
          </p>
        </div>
        <div className="flex items-center border border-gray-700 rounded-lg pl-3 py-1">
          <div className="text-base">{t("workspace_key")}:</div>
          <SelectInput
            options={workspaceOptions}
            value={selectedWorkspaceId}
            onChange={setSelectedWorkspaceId}
            disabled={selectedWorkspaceId && workspaceOptions?.length <= 1}
            className="w-full ml-3 border-none"
          />
        </div>
        <div className="space-y-3 flex-1 max-h-80 sm:max-h-96 overflow-y-auto scrollbar-light dark:scrollbar">
          {conditions &&
            conditions?.map((condition, index) => {
              const criteria = getCriteria(condition);
              const groups = getGroupNames(condition);
              return (
                <div
                  key={index}
                  className="bg-gray-300 dark:bg-gray-900 flex justify-between px-4 py-5 w-full rounded-lg cursor-pointer"
                  onClick={() => {
                    setCurrentCondition(clone(condition));
                    toggleEditCondition(true);
                  }}
                >
                  <div className="space-y-2">
                    <p className="text-gray-700 dark:text-gray-500 uppercase text-base">
                      {t("automated_share.condition")} {index + 1}
                    </p>
                    <p className="text-md text-gray-800 dark:text-gray-200">
                      <span className="text-teal-500 dark:text-teal-300">
                        {t("automated_share.criteria")}{" "}
                      </span>
                      {criteria} <span className="text-gray-700">|</span>{" "}
                      <span className="text-teal-500 dark:text-teal-300">
                        {t("automated_share.shared_with")}{" "}
                      </span>
                      {groups}
                    </p>
                  </div>
                  <AwesomeIcon
                    name={faPenToSquare}
                    className="block text-gray-700 darK:text-gray-500"
                  />
                </div>
              );
            })}
        </div>
        <button
          onClick={() => {
            toggleEditCondition(true);
          }}
          className={
            "inline-flex border bg-gray-600 dark:border-teal-300 dark:hover:border-teal-800 dark:bg-transparent dark:hover:bg-teal-800 hover:bg-teal-800 w-full space-x-4 items-center justify-center text-base font-medium rounded-6 text-gray-100 py-4 px-4 !mb-4 sm:!mb-0"
          }
        >
          <AwesomeIcon name={faPlus} className="block text-white cursor-pointer" />
          <p>{t("automated_share.rule_button")}</p>
        </button>
      </div>
      <ConditionsModal
        allFindings={allFindings}
        isVisible={isEditConditionOpen}
        onClose={onCloseConditionModel}
        findings={currentCondition}
        groups={groups}
        workspaceOptions={workspaceOptions}
        currentWorkspaceId={selectedWorkspaceId}
      />
    </>
  );
}

AutomatedShare.defaultProps = {
  onClose: () => {},
  isModal: false,
  isVisible: false,
};

AutomatedShare.propTypes = {
  onClose: PropTypes.func,
  isModal: PropTypes.bool,
  isVisible: PropTypes.bool,
};

export default AutomatedShare;
