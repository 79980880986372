import { useEffect, useState } from "react";
import { clone, get, isEmpty } from "lodash";
import classNames from "classnames";
import Store from "../../utils/Store";
import Button from "../common/Button";
import TextInput from "../FormComponents/TextInput";
import InviteUsers from "../InviteUsers";
import UserRow from "../UserGroupManagement/UserRow";
import useMutateSingleWorkspace from "./services/useMutateSingleWorkspace";
import Toast from "../Toast";
import ConfirmationModal from "../ConfirmationModal";
import { getTrimmedId } from "./utils";
import {
  customRegexValidation,
  emptyFieldValidation,
  startCharCheckValidation,
  validateFields,
} from "../../utils/regexValidation";
import { useTranslation } from "react-i18next";
import Checkbox from "../common/Checkbox";
import useCreateNewWorkspace from "./services/useCreateNewWorkspace";

const EditWorkspace = (props) => {
  const { data, onCloseModal, defaultId, refetchWorkspace, refetchProfile } = props;

  const { t } = useTranslation();
  const workspaceId = get(data, ["id"]);
  const id = getTrimmedId(get(data, ["uuid"]));
  const patientCount = get(data, ["patient_count"]);
  const memberCount = get(data, ["member_count"]);
  const name = get(data, ["name"]);
  const isEditMode = !isEmpty(data);

  const convertResponseToForm = () => {
    const users = _.get(data, ["users"], []);

    return users.map((u) => {
      const permissions = _.get(u, ["permissions"], []);
      return {
        name: _.get(u, ["name"], ""),
        email: _.get(u, ["email"], ""),
        saved: false,
        canUpload: permissions.includes("CREATE_PATIENTS"),
        selected: true,
      };
    });
  };

  const [workspaceName, setWorkspaceName] = useState(name);
  const [isChecked, setIsChecked] = useState(false);
  const [isFormDirty, setFormDirty] = useState(false);
  const [collaboratorsList, setCollaboratorsList] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    if (workspaceId === defaultId) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [defaultId, workspaceId]);

  useEffect(() => {
    setCollaboratorsList(convertResponseToForm());
    setWorkspaceName(name);
  }, [data]);

  const mutateSingleWorkspace = useMutateSingleWorkspace(workspaceId);
  const createNewWorkspace = useCreateNewWorkspace();

  useEffect(() => {
    if (mutateSingleWorkspace.status === "success") {
      const newList = collaboratorsList.filter((collab) => collab.selected);
      setCollaboratorsList(newList);
    }
  }, [mutateSingleWorkspace.status]);

  const onClickSave = async () => {
    const validationResults = validateFields([
      {
        fn: emptyFieldValidation,
        value: workspaceName,
        err: ` ${t("workspace_management.validation.empty_field")},`,
      },
      {
        fn: customRegexValidation,
        value: workspaceName,
        err: ` ${t("workspace_management.validation.special_char")},`,
      },
      {
        fn: startCharCheckValidation,
        value: workspaceName,
        err: ` ${t("workspace_management.validation.start")},`,
      },
    ]);
    if (validationResults) {
      setError(validationResults);
    }
    if (isFormDirty && !validationResults) {
      // if its dirty then save it
      // for the payload, call post call
      setError("");
      const payload = {
        name: workspaceName,
        user_updates: [],
        is_default: isChecked,
      };
      payload.user_updates = collaboratorsList.map((collab) => {
        const dot = {
          email: collab.email,
        };

        if (!collab.selected) {
          // means deleting an user
          dot.permissions = [];
        } else {
          dot.permissions = ["WRITE_PATIENTS"];
          if (collab.canUpload) {
            dot.permissions.push("CREATE_PATIENTS");
          }
        }

        return dot;
      });

      try {
        if (isEditMode) {
          mutateSingleWorkspace
            .mutateAsync(payload)
            .then(() => {
              refetchWorkspace();
              refetchProfile();
              Toast.success(t("workspace_management.toast.success"));
            })
            .catch((err) => {
              Toast.error(t("workspace_management.toast.failed"));
            });
        } else {
          createNewWorkspace
            .mutateAsync(payload)
            .then(() => {
              refetchWorkspace();
              refetchProfile();
              Toast.success(t("workspace_management.toast.create_success"));
            })
            .catch((err) => {
              Toast.error(t("workspace_management.toast.failed"));
            });
        }
        setFormDirty(false);
        onCloseModal();
      } catch (e) {
        setFormDirty(false);
        onCloseModal();
        Toast.success(t("workspace_management.toast.failed"));
      }
    }
  };

  const onChangeWorkspaceName = (val) => {
    setWorkspaceName(val);
    if (!isFormDirty) {
      setFormDirty(true);
    }
  };
  const onAddNewCollaborators = (_collaborators) => {
    const _collaboratorsList = clone(collaboratorsList);

    _collaborators.forEach((_collaborator) => {
      if (!_collaboratorsList.some((c) => c.email === _collaborator.value)) {
        _collaboratorsList.unshift({
          name: _collaborator.text,
          email: _collaborator.value,
          selected: true,
          canUpload: true,
          saved: false,
        });
      }
    });
    setFormDirty(true);
    setCollaboratorsList(_collaboratorsList);
  };

  const onToggleCollaborator = ({ email }, update) => {
    let hasSet = false;
    const updatedList = collaboratorsList.map((item) => {
      if (item.email === email) {
        if (update.delete) {
          item.selected = !item.selected;
          item.canUpload = false;
          hasSet = true;
        }
        if (update.upload) {
          item.canUpload = !item.canUpload;
          hasSet = true;
        }
        return item;
      }
      return item;
    });

    if (!isFormDirty && hasSet) {
      setFormDirty(true);
    }
    setCollaboratorsList(updatedList);
  };

  const onClickCancel = () => {
    if (isFormDirty) {
      // open confirmation modal and close this
      Store.update((s) => {
        s.isConfirmationModalOpen = true;
      });
    } else {
      // close the modal
      onCloseModal();
    }
  };

  const onClickConfirmUnsave = () => {
    Store.update((s) => {
      s.isConfirmationModalOpen = false;
    });
    setFormDirty(false);
    onCloseModal();
  };

  return (
    <>
      <div
        className={classNames("text-left p-5 flex-1", {
          "opacity-60 pointer-events-none": mutateSingleWorkspace.isLoading,
        })}
      >
        <div className="flex justify-between items-start">
          <div className="text-gray-800 dark:text-gray-200">
            <div>{t("workspace_management.edit_text")}</div>
            <div className="text-sm">{t("workspace_management.add_remove_text")}</div>
          </div>
          {isEditMode && (
            <div className="rounded-md border border-gray-300 px-4 py-2 text-[12px]">
              {patientCount} {t("patients_key")}
            </div>
          )}
        </div>
        <TextInput
          label="Workspace Name"
          placeholder="Enter workspace Name"
          name="workspaceName"
          id="workspaceName"
          wrapperClassName="my-5"
          required
          value={workspaceName}
          rightContent={() => {
            return (
              <>
                {isEditMode && (
                  <div className="flex px-2 text-[14px] tracking-wider">
                    <div className="text-gray-900 dark:text-gray-100 mr-4">
                      {memberCount} {t("members_key")}
                    </div>
                    <div className="text-gray-600 dark:text-gray-300 uppercase">{id}</div>
                  </div>
                )}
              </>
            );
          }}
          onChange={(val) => onChangeWorkspaceName(val)}
        />
        <div className="flex flex-col h-full space-y-3">
          <div className="flex justify-between items-end mb-2">
            <p className="text-base">{t("workspace_management.master_user_list")}</p>
            <Checkbox
              name="defaultWorkspace"
              id="defaultWorkspace"
              checked={isChecked}
              disabled={workspaceId === defaultId}
              className="pb-4"
              label={t("workspace_management.default_workspace")}
              onChange={(value) => {
                setFormDirty(true);
                setIsChecked(value);
              }}
            />
          </div>
          <div>
            <InviteUsers onSubmit={onAddNewCollaborators} />
            <div className="text-sm text-gray-800 dark:text-gray-300 mt-2">
              {t("workspace_management.all_users_default")}
            </div>
          </div>

          <div className="bg-gray-300 dark:bg-gray-900 py-2 rounded-lg mt-4">
            <div className="h-48 overflow-y-auto scrollbar-light dark:scrollbar divide-y divide-gray-800">
              {!isEmpty(collaboratorsList) &&
                collaboratorsList.map((user, index) => {
                  const { email } = user;
                  return (
                    <UserRow
                      key={email}
                      user={user}
                      index={index}
                      hasUpload
                      onChange={onToggleCollaborator}
                    />
                  );
                })}

              {isEmpty(collaboratorsList) && (
                <div className="flex flex-col h-full w-full items-center justify-center text-gray-700 cursor-default">
                  {t("workspace_management.no_members")}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="text-red-500 px-6 pb-2 text-left mb-4 sm:mb-0">{error}</div>
      <div className="mt-4 p-4 border-t border-t-gray-700 flex items-center justify-between">
        <div />
        <div className="">
          <Button
            isSecondary
            disabled={mutateSingleWorkspace.isLoading}
            className="mr-4 px-7 py-4"
            onClick={onClickCancel}
          >
            <div className="">{t("button.cancel")}</div>
          </Button>
          <Button
            isPrimary
            isLoading={mutateSingleWorkspace.isLoading}
            className="px-7 py-4"
            disabled={!isFormDirty}
            onClick={onClickSave}
          >
            <div className="">{t("button.save")}</div>
          </Button>
        </div>
      </div>
      <ConfirmationModal
        onClose={() => {
          Store.update((s) => {
            s.isConfirmationModalOpen = false;
          });
        }}
        onClickConfirm={onClickConfirmUnsave}
        labels={{
          title: t("confirmation_modal_unsaved"),
          label: t("confirmation_modal_message"),
          yes: t("yes_key"),
          no: t("no_key"),
        }}
      />
    </>
  );
};

export default EditWorkspace;
