import { isEmpty } from "lodash";
import servicesUtils from "../../../utils/servicesUtils";
import constants from "../../../utils/constants";

const getEmergencyPatients = (apiParams = {}) => {
  return servicesUtils.postService("/patients/", {
    emergency: true,
    page_size: 30, // exceptionally keeping 30 for emergency patients listing otherwise sohould be constants.PAGE_SIZE,
    ...apiParams,
  });
};

const postSettings = (params) => {
  return servicesUtils.postService("/settings/", params);
};

const validateUserConfig = (userSettingsConfig) => {
  let isValid = true;

  const { worklist } = userSettingsConfig;

  // worklist validation
  if (worklist) {
    const { tags } = worklist;

    if (tags) {
      Object.keys(tags).map((study) => {
        const tagsValueArray = [];

        for (let tagNo in tags[study]) {
          const tag = tags[study][tagNo];
          if (tagsValueArray.includes(tag.value)) isValid = false;
          if (!isEmpty(tag.value)) tagsValueArray.push(tag.value);
        }
      });
    }
  }
  return isValid;
};

export { postSettings, validateUserConfig, getEmergencyPatients };
