import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useRemoveUsers = () => {
  return useMutation((params) =>
    servicesUtils.postService("/accounts/groups/remove_users", params)
  );
};

export default useRemoveUsers;
