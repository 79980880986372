import SelectInput from "./SelectInput";
import PropTypes from "prop-types";

const Unit = ({ unit, onChangeUnit }) => {
  if (!!unit) {
    if (typeof unit === "string") {
      return <span className="text-md px-3 text-gray-400">{unit}</span>;
    } else if (unit.length > 1) {
      const unitOptions = unit.map((un, idx) => ({ id: idx, value: un, label: un }));
      return (
        <SelectInput
          options={unitOptions}
          value={unit}
          onChange={onChangeUnit}
          className="bg-gray-800 border-none ml-3 w-20 py-2"
        />
      );
    }
  }
  return null;
};

Unit.defaultProps = {
  unit: "",
  onChangeUnit: () => {},
};

Unit.propTypes = {
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChangeUnit: PropTypes.func,
};

export default Unit;
