import classNames from "classnames";
import { get } from "lodash";

const SubCategories = ({
  studyName,
  userSettingsConf,
  dropdownCategory,
  subCategories,
  setUserSettingsConf,
  tagNo,
  labelMappings,
}) => {
  return (
    <div
      className={classNames(
        "h-[1px] grow text-gray-800 dark:text-gray-100 flex flex-col mt-2",
        {}
      )}
    >
      <div className={classNames("flex justify-start space-x-3 my-1")}>
        <span className="text-[12px] font-semibold">TAGS</span>
        <span
          className={classNames(
            "text-[12px] text-gray-700 dark:text-gray-200 text-left",
            {}
          )}
        >
          Tags will be displayed on worklist only if selected tag is present in patient.
        </span>
      </div>
      <div
        className={classNames(
          "flex flex-col overflow-y-auto scrollbar-light px-3 rounded h-full"
        )}
      >
        {subCategories &&
          Object.keys(subCategories).map((subCategory) => {
            return (
              <div key={subCategory} className="mb-5">
                <div className="text-left mb-2 bg-gray-100 dark:bg-gray-800 dark:text-gray-200 text-[12px] sticky top-0 pt-2">
                  {labelMappings[subCategory]}
                </div>
                <div className="flex flex-wrap gap-3 text-[12px] px-3">
                  {subCategories[subCategory].map((tag_) => {
                    let tag;
                    if (typeof tag_ === "object") {
                      tag = tag_.choice;
                    } else {
                      tag = tag_;
                    }
                    return (
                      <div
                        key={tag}
                        className={classNames(
                          "bg-gray-200 dark:bg-gray-700 rounded-full px-4 py-2 cursor-pointer hover:bg-teal-400 dark:hover:bg-teal-400 hover:text-gray-100",
                          {
                            "bg-teal-400 dark:bg-teal-400 !text-gray-100":
                              typeof tag_ === "object"
                                ? tag_.id ===
                                  userSettingsConf.worklist.tags[studyName][tagNo].value
                                : tag ===
                                  userSettingsConf.worklist.tags[studyName][tagNo].value,
                          }
                        )}
                        onClick={() => {
                          const prevValue = get(
                            userSettingsConf,
                            ["worklist", "tags", studyName, tagNo, "value"],
                            null
                          );
                          const selectedValue = typeof tag_ === "object" ? tag_.id : tag_;

                          setUserSettingsConf({
                            ...userSettingsConf,
                            worklist: {
                              ...userSettingsConf.worklist,
                              tags: {
                                ...userSettingsConf.worklist.tags,
                                [studyName]: {
                                  ...userSettingsConf.worklist.tags[studyName],
                                  [tagNo]: {
                                    category:
                                      selectedValue === prevValue
                                        ? null
                                        : dropdownCategory,
                                    value:
                                      selectedValue === prevValue ? null : selectedValue,
                                  },
                                },
                              },
                            },
                          });
                        }}
                      >
                        {labelMappings[tag] || tag}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default SubCategories;
