import React from "react";
import Modal from "../common/Modal";
import Button from "../common/Button";
import { useTranslation } from "react-i18next";

const DeleteModal = ({ deleteModal, setDeleteModal, onDelete, title, content }) => {
  const { t } = useTranslation();
  const onCloseModal = () => {
    setDeleteModal(false);
  };
  return (
    <Modal
      title={title}
      bgOpacity
      onClose={onCloseModal}
      show={deleteModal}
      className="flex flex-col w-full h-1/3 sm:w-7/12 lg:w-6/12 xl:w-4/12 sm:!h-auto sm:!max-h-[80%] overflow-hidden"
    >
      <div className="pt-2 flex flex-col h-full pb-6">
        <p className="text-justify px-4 border-t border-b grow mb-6 pt-6 sm:pb-6 border-gray-700">
          {content}
        </p>
        <div className="flex items-center sm:justify-end space-x-4 px-4">
          <Button isSecondary className="grow-1 w-full sm:w-40" onClick={onCloseModal}>
            {t("button.cancel")}
          </Button>
          <Button
            isPrimary
            onClick={onDelete}
            className="sm:mt-0 grow-1 ml-4 w-full sm:w-40"
          >
            {t("button.delete")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
