import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { TablePlugin } from "@lexical/react/LexicalTablePlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import classNames from "classnames";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import editorTheme from "./themes/editorTheme";
import {
  $createParagraphNode,
  $getRoot,
  $getSelection,
  $insertNodes,
  $isTextNode,
} from "lexical";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import ImagesPlugin from "./plugins/ImagePlugin";
import { ImageNode } from "./nodes/ImageNode";
import DropdownMenu from "../common/DropdownMenu";
import { get, isEmpty } from "lodash";
import Button from "../common/Button";
import DragDropPaste from "./plugins/DragDropPastePlugin";
import AwesomeIcon from "../AwesomeIcon";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";

const Placeholder = () => {
  return (
    <div className="text-gray-500 overflow-hidden absolute overflow-ellipsis top-5 left-3 pointer-events-none inline-block select-none" />
  );
};

const ReportEditor = (props) => {
  const { impression, onChange, isMobile, isFocused, templatesList } = props;
  const editorRef = useRef();
  const [search, setSearch] = useState("");

  const editorConfig = {
    namespace: "PlaygroundEditor",
    theme: editorTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode,
      HorizontalRuleNode,
      ImageNode,
    ],
  };

  const onApplyTemplate = (template) => {
    editorRef.current.update(() => {
      const selection = $getSelection();
      if (template && selection && editorRef) {
        const parser = new DOMParser();
        const dom = parser.parseFromString(template, "text/html");
        const nodes = $generateNodesFromDOM(editorRef.current, dom);
        selection.insertNodes(nodes);
      }
    });
    setSearch("");
  };

  const getTemplateSelectMenu = () => {
    let filteredTemplateList = templatesList;
    if (search) {
      filteredTemplateList = templatesList.filter((item) =>
        get(item, ["name"], "").toLowerCase().includes(search?.toLowerCase())
      );
    }
    return filteredTemplateList?.map((item) => {
      return {
        name: (
          <div className="flex px-4 py-3 items-center justify-start">
            <p className="text-md">{item.name}</p>
          </div>
        ),
        onClick: () => onApplyTemplate(item.template),
      };
    });
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.update(() => {
        if (impression) {
          const parser = new DOMParser();
          const dom = parser.parseFromString(impression, "text/html");
          let nodes = $generateNodesFromDOM(editorRef.current, dom);
          nodes = nodes.map((node) => {
            if ($isTextNode(node)) {
              return $createParagraphNode().append(node);
            }
            return node;
          });
          $getRoot().clear().select();
          $insertNodes(nodes);
        }
      });
    }
  }, [editorRef, impression]);

  const FocusUnfocusPlugin = () => {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
      if (editor && isMobile) {
        if (isFocused) {
          editor.focus();
        } else {
          editor.blur();
        }
      }
    }, [editor, isFocused]);
    return null;
  };

  const CustomOnChangePlugin = () => {
    const [editor] = useLexicalComposerContext();

    editorRef.current = editor;
    useEffect(() => {
      return editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          const md = $generateHtmlFromNodes(editor);
          onChange(md);
        });
      });
    }, [editor]);
    return null;
  };

  const onStopEventBubbling = (e) => {
    e.stopPropagation();
  };

  return (
    <>
      {!isEmpty(templatesList) && (
        <div className="mt-3 flex justify-end mx-4">
          <DropdownMenu
            isSearch={true}
            search={search}
            setSearch={setSearch}
            options={getTemplateSelectMenu()}
            Icon={() => (
              <Button
                isSecondary
                className="grow-1 flex space-x-2 px-4 py-2"
                isPrimary={false}
              >
                <AwesomeIcon name={faPlus} size="sm" className="w-5" />
                <p className="text-md">Add Template</p>
              </Button>
            )}
          />
        </div>
      )}

      <div
        className="flex flex-col h-full w-full p-4 overflow-y-hidden"
        onScroll={onStopEventBubbling}
        onTouchMove={onStopEventBubbling}
        onTouchStart={onStopEventBubbling}
        onTouchEnd={onStopEventBubbling}
      >
        <LexicalComposer initialConfig={editorConfig}>
          <div
            className={classNames(
              "flex flex-col bg-gray-200 text-black relative w-full h-full overflow-x-auto text-left rounded overflow-y-hidden editor-shell"
            )}
          >
            <ToolbarPlugin />
            <RichTextPlugin
              contentEditable={
                <ContentEditable className="editor-input flex flex-col h-full w-full overflow-y-auto p-3" />
              }
              placeholder={<Placeholder template={impression} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <TablePlugin hasCellBackgroundColor />
            <ClearEditorPlugin />
            <CodeHighlightPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <FocusUnfocusPlugin />
            <CustomOnChangePlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <ImagesPlugin />
            <DragDropPaste />
          </div>
        </LexicalComposer>
      </div>
    </>
  );
};

export default ReportEditor;

ReportEditor.defaultProps = {
  impression: "",
  onChange: () => {},
};
ReportEditor.propTypes = {
  impression: PropTypes.string,
  onChange: PropTypes.func,
  isFocused: PropTypes.bool.isRequired,
};
