import _slicedToArray from "/vercel/path1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement, mergeRegister } from "@lexical/utils";
import { $createParagraphNode, $insertNodes, $isRootOrShadowRoot, COMMAND_PRIORITY_EDITOR, createCommand } from "lexical";
import { useEffect } from "react";
import { $createImageNode, ImageNode } from "../nodes/ImageNode";
export var INSERT_IMAGE_COMMAND = createCommand("INSERT_IMAGE_COMMAND");
export default function ImagesPlugin(_ref) {
  var captionsEnabled = _ref.captionsEnabled;
  var _useLexicalComposerCo = useLexicalComposerContext(),
    _useLexicalComposerCo2 = _slicedToArray(_useLexicalComposerCo, 1),
    editor = _useLexicalComposerCo2[0];
  useEffect(function () {
    if (!editor.hasNodes([ImageNode])) {
      throw new Error("ImagesPlugin: ImageNode not registered on editor");
    }
    return mergeRegister(editor.registerCommand(INSERT_IMAGE_COMMAND, function (payload) {
      var imageNode = $createImageNode(payload);
      $insertNodes([imageNode]);
      if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
        $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
      }
      return true;
    }, COMMAND_PRIORITY_EDITOR));
  }, [captionsEnabled, editor]);
  return null;
}