import { useState } from "react";
import { useStoreState } from "pullstate";
import classNames from "classnames";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import useWorkspacesData from "./services/useWorkspaceData";
import useSingleWorkspace from "./services/useSingleWorkspace";
import { useTranslation } from "react-i18next";
import EditWorkspace from "./EditWorkspace";
import WorkspaceList from "./WorkspaceList";
import useAuthService from "../../hooks/useAuthService";
import { get } from "lodash";

function WorkspaceManagement() {
  const { t } = useTranslation();
  const [editWorkspaceSelection, setEditWorkspaceSelection] = useState(null);
  const [newWorkspaceSelection, setNewWorkspaceSelection] = useState(false);
  const { isVisible, userInfo } = useStoreState(Store, (s) => ({
    isVisible: s.isWorkspaceOpen,
    userInfo: s.userInfo,
  }));

  const { refetch: refetchProfile } = useAuthService(false);
  const defaultId = get(userInfo, ["default_workspace"], "");

  const {
    data: workspaceData,
    isError: isErrorWorkspace,
    isLoading: isLoadingWorkspace,
    refetch: refetchWorkspace,
  } = useWorkspacesData({ isOpen: isVisible });

  const {
    data: singleWorkspaceData,
    isError: isErrorSingleWorkspace,
    isLoading: isLoadingSingleWorkspace,
    error,
  } = useSingleWorkspace({ isOpen: isVisible, id: editWorkspaceSelection });

  const onCloseModal = () => {
    if (editWorkspaceSelection || newWorkspaceSelection) {
      setEditWorkspaceSelection("");
      setNewWorkspaceSelection(false);
    } else {
      Store.update((s) => {
        s.isWorkspaceOpen = false;
      });
    }
  };

  const onClickEditWorkspace = (id) => {
    setEditWorkspaceSelection(id);
  };

  const onClickNewWorkspace = () => {
    setEditWorkspaceSelection(null);
    setNewWorkspaceSelection(true);
  };

  return (
    <Modal
      title={t("setting_workspace")}
      onClose={onCloseModal}
      show={isVisible}
      isLoading={isLoadingSingleWorkspace || isLoadingWorkspace}
      className={classNames(
        "!flex !flex-col sm:w-9/12 lg:w-6/12 xl:w-5/12 !min-h-[60%] overflow-hidden",
        { "!max-h-fit": !editWorkspaceSelection && !newWorkspaceSelection }
      )}
    >
      {/*<div className='sm:w-9/12 lg:w-6/12 xl:w-6/12 h-4/6'></div>*/}
      <div className="p-4 !h-full flex flex-col">
        {!editWorkspaceSelection &&
          !newWorkspaceSelection &&
          (isLoadingWorkspace ? (
            <div className="p-8 text-gray-300">
              {t("workspace_management.retrieving")}
            </div>
          ) : (
            <WorkspaceList
              workspaceData={workspaceData}
              onClickEditWorkspace={onClickEditWorkspace}
              onClickNewWorkspace={onClickNewWorkspace}
              defaultId={defaultId}
              refetchWorkspace={refetchWorkspace}
              refetchProfile={refetchProfile}
            />
          ))}

        {(editWorkspaceSelection || newWorkspaceSelection) &&
          (isLoadingSingleWorkspace ? (
            <div className="p-8 text-gray-300">{t("workspace_management.loading")}</div>
          ) : (
            <EditWorkspace
              data={editWorkspaceSelection ? singleWorkspaceData : {}}
              onCloseModal={onCloseModal}
              defaultId={defaultId}
              refetchProfile={refetchProfile}
              refetchWorkspace={refetchWorkspace}
            />
          ))}
      </div>
    </Modal>
  );
}

export default WorkspaceManagement;
