import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useDeleteGroup = () => {
  return useMutation((params) =>
    servicesUtils.postService("/accounts/groups/delete", params)
  );
};

export default useDeleteGroup;
