import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useAddTemplate = () => {
  return useMutation((params) =>
    servicesUtils.postService("/report_templates/create/", params)
  );
};

export default useAddTemplate;
