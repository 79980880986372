import classNames from "classnames";

export default function TextareaInput({
  label,
  value,
  onChange,
  name,
  id,
  placeholder,
  className,
  required,
  unit,
  disabled,
  type,
  inputMode,
  bg,
  title,
  rows,
  isClinicalField,
  wrapperClassName,
  inputClassName,
}) {
  return (
    <div
      className={classNames(
        "relative rounded-md py-3 px-2 text-gray-900 dark:text-gray-100",
        {
          "border border-gray-500": !isClinicalField,
          "opacity-50": disabled,
          [wrapperClassName]: !!wrapperClassName,
        }
      )}
    >
      <div className={classNames("flex text-base pb-4")}>{title}</div>
      <label
        className={classNames("text-md text-left", {
          "dark:bg-gray-800 absolute left-4 -top-3 px-4": !isClinicalField,
          "bg-transparent flex mb-5 text-base": isClinicalField,
        })}
      >
        {label} {!!required && <span className="text-red-500">*</span>}
      </label>
      <div className="flex items-center">
        <div
          className={classNames("rounded border-0", {
            "bg-gray-100 dark:bg-gray-800 pb-2 px-3": isClinicalField,
            [className]: !!className,
          })}
        >
          <textarea
            type={type || "text"}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={classNames(
              "block w-full rounded py-5 px-4 dark:bg-transparent scrollbar-light dark:scrollbar bg-gray-100 dark:border-0 placeholder:text-sm placeholder:sm:text-base resize-none border border-gray-300 focus:border-gray-300",
              { [inputClassName]: !!inputClassName }
            )}
            required={required}
            disabled={disabled}
            inputMode={inputMode}
            rows={rows}
          />
        </div>
        {!!unit && <span className="text-sm px-3 text-gray-400">{unit}</span>}
      </div>
    </div>
  );
}
