import { isEmpty } from "lodash";
import UserIcon from "../common/UserIcon";

const InviteesList = ({ title, list, onSelect, isLoading }) => {
  return (
    <>
      {!!title && (isLoading || !isEmpty(list)) && (
        <div className="border-y border-gray-400 dark:border-gray-800 text-md font-medium p-3 mx-3">
          {title}
        </div>
      )}

      {!isEmpty(list) && (
        <div className="divide-y divide-gray-800 py-3">
          {list.map((item) => {
            const { value, text, description } = item;
            return (
              <div
                key={value}
                value={value}
                tabIndex={0}
                className="flex items-center justify-start p-2 hover:bg-teal-500 hover:text-white dark:hover:bg-teal-700 cursor-pointer w-full text-left"
                onClick={(e) => {
                  e.stopPropagation();
                  onSelect(item);
                }}
              >
                <div className="flex items-center justify-center h-full">
                  <UserIcon name={text} className="h-7 w-7" />
                </div>
                <div className="flex flex-col ml-3">
                  <div>{text}</div>
                  <div className="text-sm">{description}</div>
                </div>
              </div>
            );
          })}
        </div>
      )}

      {isLoading && (
        <div className="flex flex-col h-full w-full items-center justify-center overflow-hidden py-3">
          <div
            className="border-solid border-white border-2 border-r-transparent animate-spin inline-block w-5 h-5 rounded-full text-blue-600 mr-3"
            role="status"
          />
        </div>
      )}
    </>
  );
};

export default InviteesList;
