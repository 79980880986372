import classNames from "classnames";
import { get, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import useGetSignedUrlFromS3Url from "../../hooks/useGetSignedUrlFromS3Url";
import MediaViewer from "../MediaViewer";
import AwesomeIcon from "../AwesomeIcon";
import { faXmark } from "@fortawesome/pro-regular-svg-icons";
import { faFileArrowUp } from "@fortawesome/pro-light-svg-icons";

const FileUpload = ({
  label,
  value,
  onChange,
  name,
  id,
  placeholder,
  className,
  required,
  unit,
  disabled,
  type,
  inputMode,
  bg,
  patientUid,
  inputClassName,
  subLabel,
}) => {
  const [selectedFile, setSelectedFile] = useState();
  const [viewMedia, setViewMedia] = useState("");
  const [preview, setPreview] = useState("");
  const s3Url = typeof value === "string" ? value : "";
  const { data } = useGetSignedUrlFromS3Url(patientUid, s3Url);
  const signedUrl = patientUid ? get(data, ["url"], "") : s3Url;

  useEffect(() => {
    if (!value) {
      setPreview("");
    }
  }, [value]);

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    onChange(selectedFile);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const handleUpload = (e) => {
    const upload_files = get(e, ["target", "files"], {});
    if (isEmpty(upload_files)) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(upload_files[0]);
  };

  useEffect(() => {
    if (!isEmpty(signedUrl)) {
      setPreview(signedUrl);
    }
  }, [signedUrl]);

  return (
    <>
      <MediaViewer
        isOpen={!!viewMedia}
        onClose={() => setViewMedia("")}
        url={viewMedia}
      />
      <div
        className={classNames("flex flex-col w-full text-gray-900 dark:text-gray-100", {
          "!text-opacity-50": disabled,
        })}
      >
        <div className={classNames("flex text-base pb-4")}>
          {label} {!!required && <span className="text-red-500 pl-2">*</span>}
        </div>
        {!!subLabel && <p className="text-left text-md pb-4">{subLabel}</p>}
        <div
          className={classNames(
            "flex flex-col sm:flex-row space-y-3 sm:space-y-0 justify-between",
            {
              [className]: !!className,
            }
          )}
        >
          <div
            className={classNames(
              "flex cursor-pointer items-center group justify-center h-[150px] w-full rounded-md border border-dotted border-gray-600 dark:border-gray-600 bg-gray-100 dark:bg-gray-800",
              {
                "!bg-opacity-50 !border-opacity-50": disabled,
                [inputClassName]: !!inputClassName,
              }
            )}
            onClick={() => {
              if (preview) {
                setViewMedia(preview);
              }
            }}
          >
            {preview ? (
              <div className="relative w-fit h-full">
                <div
                  className={classNames("absolute -right-2 -top-1 cursor-pointer ", {
                    hidden: disabled,
                  })}
                  onClick={(e) => {
                    e.stopPropagation();
                    setPreview("");
                    setSelectedFile();
                    onChange("");
                  }}
                >
                  <AwesomeIcon
                    name={faXmark}
                    size="sm"
                    className="w-5 hover:bg-gray-200 dark:hover:bg-gray-600 text-gray-800 dark:text-white bg-gray-300 dark:bg-gray-500 rounded-full p-1"
                  />
                </div>
                <img
                  className="object-contain h-full w-full mx-auto py-2"
                  src={preview}
                />
              </div>
            ) : (
              <div className="flex w-full items-center h-full justify-center rounded cursor-pointer">
                <label
                  htmlFor={id}
                  className="h-full w-full space-x-3 text-gray-700 group-hover:text-gray-600  dark:text-gray-100 dark:group-hover:text-gray-200 flex items-center cursor-pointer justify-center p-4 px-6"
                >
                  <AwesomeIcon name={faFileArrowUp} size="lg" className="" />
                  <p className="text-center text-base">Upload File</p>
                  <input
                    type={"file"}
                    onChange={handleUpload}
                    name={name}
                    id={id}
                    className="w-full hidden"
                    accept="image/*"
                    required={required}
                    disabled={disabled}
                    inputMode={inputMode}
                  />
                </label>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
