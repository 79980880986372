import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useGetReport = (isOpen) => {
  return useQuery(
    ["reportTemplate", isOpen],
    () => {
      return servicesUtils.getService("/report_templates/");
    },
    {
      enabled: isOpen,
    }
  );
};

export default useGetReport;
