import { get } from "lodash";
import PropTypes from "prop-types";
import Unit from "./Unit";
import classNames from "classnames";

const BloodPressure = (props) => {
  const { value, onChange, id, unit, onChangeUnit, title, required, disabled } = props;
  const values = value && value.split("/");
  const low = get(values, [0], "");
  const high = get(values, [1], "");

  const onChangeValue = (value, type) => {
    if (!disabled && (value === "" || /^[0-9\b]+$/.test(value))) {
      const result = { low, high };
      result[type] = value;
      onChange(`${result.low}/${result.high}`);
    }
  };

  return (
    <div className="flex flex-col mb-3">
      <div className="flex mb-5">
        {title}
        {!!required && <span className="text-red-500 pl-2">*</span>}
      </div>
      <div
        className={classNames("flex items-center", {
          "opacity-50": disabled,
        })}
      >
        <div className="flex items-center bg-gray-800 p-3 rounded-6 w-44">
          <input
            id={`${id}_low`}
            className="w-1/2 bg-transparent mr-4 border-b border-teal-500 text-center focus:outline-none"
            inputMode="numeric"
            placeholder="- -"
            value={low}
            maxLength={3}
            disabled={disabled}
            onChange={(e) => onChangeValue(e.target.value, "low")}
          />
          /
          <input
            id={`${id}_high`}
            className="w-1/2 bg-transparent ml-4 border-b border-teal-500 text-center focus:outline-none"
            inputMode="numeric"
            placeholder="- -"
            value={high}
            maxLength={3}
            disabled={disabled}
            onChange={(e) => onChangeValue(e.target.value, "high")}
          />
        </div>
        <Unit unit={unit} onChangeUnit={onChangeUnit} />
      </div>
    </div>
  );
};

BloodPressure.defaultProps = {
  value: "",
  onChange: () => {},
  id: "",
  required: false,
  disabled: false,
};

BloodPressure.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default BloodPressure;
