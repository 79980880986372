import classNames from "classnames";
import { useState } from "react";
import AwesomeIcon from "../AwesomeIcon";
import { faMinus } from "@fortawesome/pro-regular-svg-icons";

export default function ToggleSwitch({
  defaultValue,
  label,
  onClick,
  iconLeft,
  iconRight,
}) {
  const [value, setValue] = useState(defaultValue);

  const changeValue = () => {
    setValue((prev) => !prev);
    onClick();
  };

  return (
    <div className="flex items-center justify-center w-full">
      <label className="flex items-center cursor-pointer">
        <div className="text-gray-100 select-none">{label}</div>
        <div className="relative">
          <input
            type="checkbox"
            className="sr-only"
            value={!value}
            onChange={changeValue}
          />
          <div className="flex items-center justify-evenly bg-black border border-gray-600 w-[38px] h-[20px] rounded-full">
            {iconLeft}
            {iconRight}
          </div>
          <div
            className={classNames(
              "absolute top-1 bg-gray-600 w-5 h-5 rounded-full transition flex items-center justify-center",
              {
                "right-1 !bg-teal-400": !value,
                "left-1": value,
              }
            )}
          >
            {value && <AwesomeIcon name={faMinus} size="sm" />}
          </div>
        </div>
      </label>
    </div>
  );
}
