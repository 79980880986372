import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import AwesomeIcon from "../AwesomeIcon";
import {
  faCircleCheck,
  faCircleXmark,
  faTriangleExclamation,
} from "@fortawesome/pro-regular-svg-icons";

const Toast = () => {
  return (
    <ToastContainer
      position="bottom-center"
      hideProgressBar
      autoClose={3000}
      bodyClassName="toast-body"
      toastClassName="rounded-md flex mb-5 last:mb-8"
      theme="light"
    />
  );
};

Toast.success = (msg) => {
  return toast.success(msg, {
    icon: <AwesomeIcon name={faCircleCheck} className="text-green-600" />,
  });
};

Toast.warning = (msg) => {
  return toast.warning(msg, {
    icon: <AwesomeIcon name={faTriangleExclamation} className="text-yellow-600" />,
  });
};

Toast.error = (msg) => {
  return toast.error(msg, {
    icon: <AwesomeIcon name={faCircleXmark} className="text-red-600" />,
  });
};

export default Toast;
