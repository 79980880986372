import _defineProperty from "/vercel/path1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import { useQuery } from "react-query";
import servicesUtils from "../utils/servicesUtils";
import Store from "../utils/Store";
import { get } from "lodash";
var useAuthService = function useAuthService(isOpen) {
  return useQuery(["profile", isOpen], function () {
    return servicesUtils.getService("/profile/").then(function (authData) {
      var isLoggedin = get(authData, ["authenticated"], false);
      if (isLoggedin) {
        Store.update(function (store) {
          // eslint-disable-next-line no-param-reassign
          store.user = get(authData, ["details"], {});
          // eslint-disable-next-line no-param-reassign
          store.userInfo = _objectSpread({}, authData);
        });
      }
      return authData;
    });
  }, {
    enabled: isOpen,
    staleTime: 0
  });
};
export default useAuthService;