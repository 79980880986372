import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import Checkbox from "../common/Checkbox";
import Category from "./Category";
import { cloneDeep, set } from "lodash";

function SubSection(props) {
  const { subSectionDetails, isDisabled, selectionType, onChangeSubSection, indexOf } =
    props;
  const { t } = useTranslation();

  const { name, enabled, category } = subSectionDetails;

  const onChangeCategory = (data, index, isSkip) => {
    const _subSectionDetails = cloneDeep(subSectionDetails);

    if (isSkip) {
      set(_subSectionDetails, ["category", index], data);
    } else {
      if (_subSectionDetails?.selection === "single") {
        forEach(_subSectionDetails["category"], (item) => {
          if (data.name === item.name) {
            item.enabled = true;
          } else {
            item.enabled = false;
          }
        });
      } else {
        set(_subSectionDetails, ["category", index], data);
      }
    }
    onChangeSubSection(_subSectionDetails, indexOf, true);
  };

  return (
    <>
      <div className="flex justify-start py-2 pl-6 sm:pl-0">
        {selectionType === "single" ? (
          <div className={classNames({ "opacity-50": isDisabled })}>
            <input
              id={name}
              name={t(name)}
              type="radio"
              checked={enabled}
              disabled={isDisabled}
              onChange={() => {
                onChangeSubSection({ ...subSectionDetails, enabled: true }, indexOf);
              }}
              className="h-5 w-5 dark:border-gray-300 text-teal-400 bg-transparent cursor-pointer"
            />
            <label htmlFor={name} className="ml-3 dark:text-gray-200 cursor-pointer">
              {t(name)}
            </label>
          </div>
        ) : (
          <div>
            <Checkbox
              id={name}
              label={t(name)}
              checked={enabled}
              onChange={() => {
                onChangeSubSection({ ...subSectionDetails, enabled: !enabled }, indexOf);
              }}
              disabled={isDisabled}
              className={classNames("py-2", {
                "opacity-50": isDisabled,
              })}
            />
          </div>
        )}
      </div>
      {category?.map((detail, index) => (
        <div key={index} className="sm:px-7 pl-8">
          <Category
            indexOf={index}
            categoryDetails={detail}
            isDisabled={isDisabled || !enabled}
            onChangeCategory={onChangeCategory}
          />
        </div>
      ))}
    </>
  );
}

export default SubSection;
