import { getUrlAuthToken } from ".";
import LoggedInToken from "./LoggedInToken";
import constants from "./constants";
var redirectToLogin = function redirectToLogin() {
  /* eslint-disable no-console */
  console.info("Redirecting to login page.");
  var urlAuthToken = getUrlAuthToken();
  if (urlAuthToken) {
    var _window;
    (_window = window) === null || _window === void 0 || (_window = _window.localStorage) === null || _window === void 0 ? void 0 : _window.setItem(constants.PERSISTED.redirectAfterLogin, window.location.href);
  }
  var next = "".concat(window.location.origin, "/nhsx-study/");
  var apiUrl = process.env.NEXT_PUBLIC_API_URL;
  var loginURL = "".concat(apiUrl, "/accounts/login/?next=").concat(next);
  LoggedInToken.setLogInPorgress();
  if (window.ReactNativeWebView) {
    var _window2;
    // react native app
    (_window2 = window) === null || _window2 === void 0 || (_window2 = _window2.ReactNativeWebView) === null || _window2 === void 0 ? void 0 : _window2.postMessage(JSON.stringify({
      event: constants.RN_COMM_CONST.LOGIN,
      data: loginURL
    }));
  } else if (window.electronAPI) {
    // electron app
    window.open(loginURL);
  } else {
    window.location.href = loginURL;
  }
};
export default redirectToLogin;