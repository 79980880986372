import { useStoreState } from "pullstate";
import React from "react";
import ReportTemplate from ".";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import { useTranslation } from "react-i18next";

const ReportTemplateModal = () => {
  const { t } = useTranslation();
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isReportTemplateOpen,
  }));

  const onClose = () => {
    Store.update((s) => {
      s.isReportTemplateOpen = false;
    });
  };

  return (
    <>
      <Modal
        show={isVisible}
        onClose={onClose}
        title={t("setting_report")}
        className="w-full sm:w-9/12 md:w-8/12 lg:w-6/12 xl:w-5/12"
        closeOnClickAway
      >
        <ReportTemplate isOpen={isVisible} />
      </Modal>
    </>
  );
};

export default ReportTemplateModal;
