import { get } from "lodash";
import PropTypes from "prop-types";
import SelectInput from "./SelectInput";
import classNames from "classnames";

const BloodGlucose = (props) => {
  const { value, onChange, id, unit, onChangeUnit, required, disabled } = props;
  const values = value && value.split("/");
  const low = get(values, [0], "");
  const high = get(values, [1], "");
  const unitOptions = [
    {
      id: "1",
      value: "mg/dL",
      label: "mg/dL",
    },
    {
      id: "2",
      value: "mmol/L",
      label: "mmol/L",
    },
  ];
  const onChangeValue = (value, type) => {
    if (!disabled && (value === "" || /^[0-9\b]+$/.test(value))) {
      const result = { low, high };
      result[type] = value;
      onChange(id, `${result.low}/${result.high}`);
    }
  };

  return (
    <div className="flex flex-col mb-3">
      <div className="flex mb-5">
        Blood Glucose {!!required && <span className="text-red-500 pl-2">*</span>}
      </div>
      <div
        className={classNames("flex items-center", {
          "opacity-50": disabled,
        })}
      >
        <div className="flex items-center bg-gray-800 p-3 rounded-6 w-44">
          <input
            className="w-1/2 bg-transparent mr-4 border-b border-teal-500 text-center focus:outline-none"
            inputMode="numeric"
            placeholder="- -"
            value={low}
            maxLength={3}
            disabled={disabled}
            onChange={(e) => onChangeValue(e.target.value, "low")}
          />
          /
          <input
            className="w-1/2 bg-transparent ml-4 border-b border-teal-500 text-center focus:outline-none"
            inputMode="numeric"
            placeholder="- -"
            value={high}
            maxLength={3}
            disabled={disabled}
            onChange={(e) => onChangeValue(e.target.value, "high")}
          />
        </div>
        <SelectInput
          options={unitOptions}
          value={unit}
          onChange={onChangeUnit}
          disabled={disabled}
          className="bg-gray-800 border-none ml-3 w-20 py-2"
        />
      </div>
    </div>
  );
};

BloodGlucose.defaultProps = {
  value: "",
  onChange: () => {},
  id: "",
  required: false,
  disabled: false,
};

BloodGlucose.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default BloodGlucose;
