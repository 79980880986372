import classNames from "classnames";

const SingleSelectTab = ({ options, selected, onChange, title }) => {
  return (
    <div className="flex flex-col">
      <div className="flex mb-5">{title}</div>
      <div className="flex rounded-6 bg-gray-800 text-gray-100 divide-x divide-teal-500">
        {options.map((option, index) => {
          const { value, label } = option;
          return (
            <div
              key={value}
              className={classNames("py-3 px-5 cursor-pointer", {
                "bg-teal-500": selected === value,
                "rounded-l-6": index === 0,
                "rounded-r-6": index === options.length - 1,
              })}
              onClick={() => {
                onChange(value);
              }}
            >
              {label}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SingleSelectTab;
