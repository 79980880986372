import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useAddUsers = () => {
  return useMutation((params) =>
    servicesUtils.postService("/accounts/groups/add_users", params)
  );
};

export default useAddUsers;
