import servicesUtils from "../../../utils/servicesUtils";
import Store from "../../../utils/Store";


function patientsExportService(url) {
    return servicesUtils.getService(url);
}

const downloadFile = () => {
    const url = 'patients_export/get_download_link/'
    patientsExportService(url).then((response) => {
        const link = document.createElement('a');
        link.href = response.data?.csv_link;
        link.setAttribute('download', 'report.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Store.update((s) => {
            s.showExportUpdate = false;
        })
    }).catch((resp) => {
        Store.update((s) => {
            s.showExportUpdate = true;
            s.exportGenFailed = true;
        })
    })
}

export { downloadFile, patientsExportService }