import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useLeaveGroup = () => {
  return useMutation((params) =>
    servicesUtils.postService("/accounts/user/manage/leave_group", params)
  );
};

export default useLeaveGroup;
