import classNames from "classnames";
import UserIcon from "../common/UserIcon";
import Image from "next/image";
import { useTranslation } from "react-i18next";

const UserRow = (props) => {
  const { user, index, disabled, onChange, hasUpload } = props;
  const { t } = useTranslation();
  const { name, email, selected, canUpload } = user;
  const _onClickUpdate = (update) => {
    return !disabled ? onChange(user, update) : null;
  };
  return (
    <div
      key={index}
      className={classNames("flex items-center justify-between p-4 mb-2 pr-4", {
        "opacity-70": !selected,
      })}
    >
      <div className="flex space-x-2 items-center ">
        <UserIcon
          name={name || email}
          className={classNames(
            "h-7 w-7 !text-gray-100 dark:!text-gray-200 text-base mr-2 p-4",
            {
              "!bg-gray-700": !selected,
              "!bg-teal-700": selected,
            }
          )}
        />
        <div>
          <p className="text-gray-800 dark:text-gray-200 text-md">{name || "-"}</p>
          <p className="text-gray-700 dark:text-gray-400 text-md">{email}</p>
        </div>
      </div>

      <div className={classNames("flex items-center justify-end", {})}>
        <label
          htmlFor={email}
          className={classNames(
            "py-1 px-3 rounded-md border border-gray-700 flex items-center text-[12px] select-none",
            {
              hidden: !hasUpload,
            }
          )}
        >
          <input
            id={email}
            aria-describedby="group"
            name="group"
            type="checkbox"
            className="rounded border-gray-400 cursor-pointer text-teal-400 bg-transparent"
            disabled={disabled}
            checked={canUpload}
            onChange={() => _onClickUpdate({ delete: false, upload: true })}
          />
          <div className="ml-3 leading-6">{t("can_upload")}</div>
        </label>

        <div
          className={classNames("cursor-pointer ml-4 flex items-center", {
            hidden: disabled,
          })}
          onClick={() => _onClickUpdate({ delete: true, upload: false })}
        >
          {selected ? (
            <Image
              src={"/assets/icons/delete.svg"}
              alt="emergency"
              width="20"
              height="20"
            />
          ) : (
            <Image
              src={"/assets/icons/addNew.svg"}
              alt="re-add"
              className="text-white"
              width="20"
              height="20"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default UserRow;
