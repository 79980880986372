import classNames from "classnames";
import { get } from "lodash";
import Toggle from "../../../common/Toggle";
import { useTranslation } from "react-i18next";

const ActiveFindings = ({
  subCategories,
  labelMappings,
  userSettingsConf,
  setUserSettingsConf,
  studyName,
}) => {
  const { t } = useTranslation();

  const optionalTags = ["Review required"];
  const areOptionalTagsEnabled = get(
    userSettingsConf,
    ["worklist", "tags", studyName, "optional_tags_enabled"],
    true
  );

  const handleOptionalTagsChange = (val) => {
    setUserSettingsConf({
      ...userSettingsConf,
      worklist: {
        ...userSettingsConf.worklist,
        tags: {
          ...userSettingsConf.worklist.tags,
          [studyName]: {
            ...userSettingsConf.worklist.tags[studyName],
            optional_tags_enabled: val,
          },
        },
      },
    });
  };

  return (
    <div
      className={classNames(
        "h-[1px] grow text-gray-800 dark:text-gray-100 flex flex-col mt-2 overflow-y-auto scrollbar-light h-full",
        {}
      )}
    >
      <div className={classNames("flex justify-start space-x-3 my-1 px-5 text-[14px]")}>
        {t("worklist_tags.active_tags")}
      </div>
      <div className={classNames("flex flex-col px-5 rounded")}>
        {subCategories &&
          Object.keys(subCategories).map((subCategory) => {
            return (
              <div key={subCategory} className="mb-5">
                <div className="text-left mb-2 bg-gray-100 dark:bg-gray-800 dark:text-gray-200 text-md sticky top-0 pt-2">
                  {labelMappings[subCategory]}
                </div>
                <div className="flex flex-wrap gap-3 text-md">
                  {subCategories[subCategory].map((tag_) => {
                    let tag;
                    if (typeof tag_ === "object") {
                      tag = tag_.choice;
                    } else {
                      tag = tag_;
                    }
                    return (
                      <div
                        key={tag}
                        className={classNames(
                          "bg-gray-200 dark:bg-gray-700 rounded-full px-4 py-2"
                        )}
                      >
                        {labelMappings[tag] || tag}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </div>
      <div
        className={classNames(
          "flex items-center justify-start space-x-3 my-1 px-5 text-[14px]"
        )}
      >
        {t("worklist_tags.optional_tags")}
        <div className="ml-4">
          <Toggle
            value={areOptionalTagsEnabled}
            onChange={(val) => {
              handleOptionalTagsChange(val);
            }}
          />
        </div>
      </div>
      <div
        className={classNames(
          "flex flex-col overflow-y-auto scrollbar-light px-5 rounded pt-2"
        )}
      >
        <div>
          <div className="flex flex-wrap gap-3 text-md items-center">
            {optionalTags.map((tag) => {
              return (
                <div
                  key={tag}
                  className={classNames(
                    "bg-gray-200 dark:bg-gray-700 rounded-full px-4 py-2"
                  )}
                >
                  {labelMappings[tag] || tag}
                </div>
              );
            })}
            <div className="ml-4">{t("worklist_tags.qer.optional_tag_desc")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveFindings;
