import _ from "lodash";

export const customRegexValidation = (val) => {
  /* eslint-disable */
  const textRegexToSearch = /[\*\+\;<>|]/g;
  /* eslint-enable */
  if (textRegexToSearch.test(val)) {
    return false;
  }
  return true;
};

export const startCharCheckValidation = (val) => {
  const tVal = _.trim(val);
  return ["@", "=", "+"].indexOf(tVal[0]) === -1;
};

export const emptyFieldValidation = (val) => {
  if (typeof val === "string") {
    return !_.isEmpty(val && val.trim());
  }
  if (Array.isArray(val)) {
    return !_.isEmpty(val);
  }

  // if its a number, just make it true,
  // if its other than number, array, string then it should return false otherwise
  return typeof val === "number";
};

export const whiteSpaceValidation = (val) => {
  const textRegexToSearch = /[\s]/;
  /* eslint-enable */
  if (textRegexToSearch.test(val)) {
    return false;
  }
  return true;
};

export const replaceText = (val) => {
  const reg = /<([a-z]+)(?:\s+[^>]*?["'=\s]?)?(?:\/?)>/gi;
  const isValid = reg.test(val);
  if (isValid) {
    return val.replace(/[<>]/g, " ");
  }
  return val;
};

export const validateFields = (fields) => {
  let _error = "";

  fields.forEach(({ fn, value, err }) => {
    if (!fn(value)) {
      _error += err;
    }
  });
  if (_error) {
    _error = _error.slice(0, -1);
  }
  return _error;
};

export const mobNumberRegex = (val) => {
  /* eslint-disable */
  const reg = /^(\+\d{1,3}[- ]?)?\d{10}$/;
  /* eslint-disable */
  if (reg.test(val)) {
    return true;
  }
  return false;
};
