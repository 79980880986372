import classNames from "classnames";
import { useState } from "react";

export default function Tooltip(props) {
  const {
    children,
    tooltipContainerStyles, // styles for the container of children and tooltip
    tooltipText,
    position, // horizontal position of the small arrow
    verticalPosition, // vertical position of the tooltip
    tooltipStyles,
    tooltipBg,
    onMouseEnter: onMouseEnter_,
    onMouseLeave: onMouseLeave_,
    onClick: onClick_,
  } = props;

  const [isHovered, setIsHovered] = useState(false);

  const arrowBg = tooltipBg.split("-").slice(1, 3).join("-");

  return (
    <div
      className={classNames(tooltipContainerStyles, {
        relative:
          !tooltipContainerStyles.includes("absolute") &&
          !tooltipContainerStyles.includes("fixed") &&
          !tooltipContainerStyles.includes("static") &&
          !tooltipContainerStyles.includes("relative"),
      })}
    >
      <div
        className={classNames("m-2", {})}
        onMouseEnter={() => {
          if (typeof onMouseEnter_ === "function") onMouseEnter_();
          setIsHovered(true);
        }}
        onMouseLeave={() => {
          if (typeof onMouseLeave_ === "function") onMouseLeave_();
          setIsHovered(false);
        }}
        onClick={() => {
          if (typeof onClick_ === "function") onClick_();
        }}
      >
        {children}
      </div>
      {isHovered && (
        <div
          className={classNames("flex flex-col absolute z-[9999]", {
            "": verticalPosition === "bottom",
            "top-0 translate-y-[-100%]": verticalPosition === "top",
            "left-0": position === "left",
            "right-0": position === "right",
          })}
        >
          <div
            className={classNames(
              `w-0 h-0 border-l-[5px] border-l-[transparent] border-r-[5px] border-r-[transparent] border-${arrowBg}`,
              {
                "order-1 border-b-[5px]": verticalPosition === "bottom",
                "order-2 border-t-[5px]": verticalPosition === "top",
                "mr-auto ml-4": position === "left",
                "ml-auto mr-4": position === "right",
              }
            )}
          ></div>
          <div
            className={classNames(`min-w-max ${tooltipStyles} ${tooltipBg}`, {
              "order-2": verticalPosition === "bottom",
              "order-1": verticalPosition === "top",
            })}
          >
            {tooltipText}
          </div>
        </div>
      )}
    </div>
  );
}
