import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useGetConditions = (isOpen, workspaceId) => {
  return useQuery(
    ["userGroups", isOpen, workspaceId],
    () => {
      return servicesUtils.getService(`/workspaces/${workspaceId}/routing/`);
    },
    {
      enabled: isOpen && !!workspaceId,
    }
  );
};

export default useGetConditions;
