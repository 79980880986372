import { useQuery } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";
function useSingleWorkspace(params) {
  var _params$isOpen = params.isOpen,
    isOpen = _params$isOpen === void 0 ? false : _params$isOpen,
    _params$id = params.id,
    id = _params$id === void 0 ? "" : _params$id;
  return useQuery("singleWorkspace", function () {
    return servicesUtils.getService("/workspaces/".concat(id));
  }, {
    enabled: isOpen && !!id
  });
}
export default useSingleWorkspace;