import React, { useState } from "react";
import Checkbox from "../common/Checkbox";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import FindingTagModal from "./FindingsTagModal";
import { cloneDeep, filter, set } from "lodash";

function Category(props) {
  const { isDisabled, categoryDetails, onChangeCategory, indexOf } = props;

  const { name, enabled, sub_category } = categoryDetails;

  // count of totalFindings and enabledFindings
  let totalFindingsCount = 0;
  let enabledFindingsCount = 0;
  const active_sub_category = filter(sub_category, { enabled: true });

  // Iterate through sub_category and count enabled findings_list
  active_sub_category?.forEach((category) => {
    if (category?.findings_list?.length > 0) {
      enabledFindingsCount += filter(category?.findings_list, { enabled: true })?.length;
    } else if (category?.enabled) {
      enabledFindingsCount += 1;
    }
  });

  // Iterate through sub_category and count total findings_list
  sub_category?.forEach((category) => {
    if (category?.findings_list?.length > 0) {
      totalFindingsCount += category?.findings_list?.length;
    } else {
      totalFindingsCount += 1;
    }
  });

  const [showFindingModal, setShowFindingModal] = useState(false);

  const onCloseModal = () => {
    setShowFindingModal(false);
  };

  const { t } = useTranslation();

  const onChangeFindings = (data, index, nested_index) => {
    const _categoryDetails = cloneDeep(categoryDetails);
    if (typeof nested_index === "number") {
      set(_categoryDetails, ["sub_category", index, "findings_list", nested_index], data);
    } else {
      set(_categoryDetails, ["sub_category", index], data);
    }
    onChangeCategory(_categoryDetails, indexOf, true);
  };

  return (
    <>
      <div className="flex justify-start items-center space-x-4">
        <Checkbox
          id={name}
          label={t(name)}
          checked={enabled}
          disabled={isDisabled}
          className={classNames("py-2", {
            "opacity-50": isDisabled,
          })}
          onChange={() => {
            onChangeCategory({ ...categoryDetails, enabled: !enabled }, indexOf);
          }}
        />
        {(enabledFindingsCount > 0 || sub_category?.length > 0) && (
          <button
            onClick={() => {
              setShowFindingModal(true);
            }}
            disabled={!enabled || isDisabled}
            className={classNames("text-teal-300", {
              "opacity-50": !enabled || isDisabled,
            })}
          >
            {enabledFindingsCount === totalFindingsCount ? (
              <span>{t("notification_modal.all_findings")}</span>
            ) : (
              <span>
                {enabledFindingsCount} of {totalFindingsCount} findings
              </span>
            )}
          </button>
        )}
      </div>
      <FindingTagModal
        showFindingModal={showFindingModal}
        onCloseModal={onCloseModal}
        onChangeFindings={onChangeFindings}
        findingDetails={sub_category}
        name={name}
      />
    </>
  );
}

export default Category;
