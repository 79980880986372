import servicesUtils from "../../../utils/servicesUtils";
import { useMutation } from "react-query";

function useCreateNewWorkspace() {
  return useMutation((params) =>
    servicesUtils.postService(`/workspaces/create/`, params)
  );
}

export default useCreateNewWorkspace;
