/* eslint-disable */
import React, { useEffect, useState } from "react";
import _, { cloneDeep, get, set } from "lodash";
import PropTypes from "prop-types";
import Toggle from "../common/Toggle";
import Button from "../common/Button";
import Toast from "../Toast";
import Loader from "../Loader";
import { useTranslation } from "react-i18next";
import useNotificationSettings from "./hooks/useNotificationSettings";
import useSaveNotificationSettings from "./hooks/useSaveNotificationSettings";
import Section from "./Section";
import Store from "../../utils/Store";
import { useStoreState } from "pullstate";
import { subscribeUserNotification } from "../../utils/pushNotification";
import useAuthService from "../../hooks/useAuthService";
import constants from "../../utils/constants";
import classNames from "classnames";

const NotificationSettings = (props) => {
  const { t } = useTranslation();
  const { isModal, isVisible, onClose } = props;
  const [isNotificationOn, setIsNotificationOn] = useState(true);
  const [isSubscribeLoading, setSubscribeLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const saveNotificationSettings = useSaveNotificationSettings();
  const { data: settingsObj, isLoading } = useNotificationSettings(!isModal || isVisible);
  const isMobileApp = window?.ReactNativeWebView;
  const isDesktopApp = window?.electronAPI;
  const { refetch: refetchProfile } = useAuthService(false);

  const { userInfo, fcmToken, RNfcmToken } = useStoreState(Store, (s) => ({
    userInfo: s.userInfo,
    RNfcmToken: s.RNfcmToken,
    fcmToken: s.fcmToken,
  }));
  const hasPermission =
    (isMobileApp && !!RNfcmToken) || window?.Notification?.permission === "granted";
  const isSubscribed = get(userInfo, ["subscription_tokens"], []).some(
    (tokenObj) => get(tokenObj, ["token"], "") === RNfcmToken || fcmToken
  );
  const notification_snoozed = get(userInfo, ["settings", "notification_snoozed"], false);

  useEffect(() => {
    const notifications = get(settingsObj, ["notifications"], []);
    setSettings(notifications);
  }, [settingsObj]);

  useEffect(() => {
    if (!hasPermission || !isSubscribed) {
      setIsNotificationOn(false);
    } else {
      setIsNotificationOn(!notification_snoozed);
    }
  }, [notification_snoozed, hasPermission, isSubscribed]);

  const onChangeSection = (data, index) => {
    const _notifications = cloneDeep(settings);
    set(_notifications, [index], data);
    setSettings(_notifications);
  };

  const onSave = () => {
    const payload = {
      notification_snoozed: !isNotificationOn,
      notifications: settings,
    };
    saveNotificationSettings
      .mutateAsync(payload)
      .then(() => {
        refetchProfile();
        Toast.success(t("notification_modal.toast.saved_successfully"));
        onCloseModal();
      })
      .catch(() => {
        Toast.error(t("notification_modal.toast.saved_failed"));
      });
  };

  const onCloseModal = () => {
    onClose();
    if (isModal) {
      setSettings([]);
    }
  };

  const onUpdateSubscription = async () => {
    if (isMobileApp && !hasPermission) {
      const data = {
        event: constants.RN_COMM_CONST.OPEN_APP_SETTINGS,
        data: null,
      };
      window?.ReactNativeWebView?.postMessage(JSON.stringify(data));
    } else {
      setSubscribeLoading(true);
      const subscription = await subscribeUserNotification(RNfcmToken);
      if (subscription) {
        await refetchProfile();
        setSubscribeLoading(false);
      }
      setSubscribeLoading(false);
    }
  };

  const getLink = (text, link) => {
    return (
      <a
        href={link}
        target="_blank"
        className="dark:text-yellow-500 text-yellow-200 cursor-pointer font-bold pl-2"
      >
        {text}
      </a>
    );
  };

  return (
    <>
      <div className="sm:pb-5 overflow-y-auto scrollbar-light dark:scrollbar h-full">
        <div className="sm:hidden text-gray-900 dark:text-white font-medium text-xl px-6 mb-6">
          {t("setting_notification")}
        </div>
        {!hasPermission || !isSubscribed ? (
          <div className="dark:bg-yellow-900 bg-yellow-600 text-left dark:text-white text-black p-4 px-6">
            {isSubscribeLoading ? (
              <div>
                <Loader
                  size="medium"
                  label="Please wait"
                  className="mr-3"
                  containerClassName="!py-0 flex !flex-row"
                />
              </div>
            ) : isDesktopApp ? (
              <ul className="pr-2 list-decimal list-inside">
                <p>{t("notification_modal.no_notification_app")}</p>
                <li>
                  {t("notification_modal.use_via")}{" "}
                  {getLink("app.qure.ai", "https://app.qure.ai")}
                </li>
                <li>
                  {t("notification_modal.download")}{" "}
                  {getLink(
                    "Android",
                    "https://play.google.com/store/apps/details?id=app.qure.ai"
                  )}{" "}
                  <span className="lowercase">{t("or_key")}</span>
                  {getLink(
                    "iOS",
                    "https://apps.apple.com/sg/app/qure-ai/id1631113024"
                  )}{" "}
                  {t("notification_modal.mobile_devices")}
                </li>
              </ul>
            ) : (
              <p className="pr-2">
                {t("notification_modal.no_notifications_setting")}
                <span
                  className="dark:text-yellow-500 text-yellow-200 cursor-pointer font-bold pl-2"
                  onClick={onUpdateSubscription}
                >
                  {t("notification_modal.update_settings")}
                </span>
              </p>
            )}
          </div>
        ) : (
          <div className="flex justify-between dark:bg-gray-700 border-y sm:border sm:rounded-lg border-gray-600 sm:m-5 px-6 sm:px-5 py-3">
            <div className="">
              <div className="flex text-gray-800 dark:text-white text-base font-bold">
                {t("notifications_turned_on")}
              </div>
            </div>
            <div className="flex items-center justify-center h-6">
              <Toggle
                value={isNotificationOn}
                onChange={() => setIsNotificationOn(!isNotificationOn)}
              />
            </div>
          </div>
        )}

        {isLoading ? (
          <Loader size="large" containerClassName="h-full" />
        ) : (
          settings?.map((detail, index) => (
            <div
              key={index}
              className={classNames("sm:mx-7 mb-2", {
                "sm:border-b border-gray-700 sm:pb-5 mb-5":
                  settings?.length !== index + 1,
                "!pb-0": detail.name === "notify_emergency",
              })}
            >
              <Section
                indexOf={index}
                sectionDetails={detail}
                isDisabled={!isNotificationOn}
                onChangeSection={onChangeSection}
              />
            </div>
          ))
        )}
      </div>
      <div className="flex items-center flex-row justify-end p-4 border-t border-t-gray-600">
        <Button isSecondary className="grow-1 w-full sm:w-40" onClick={onCloseModal}>
          {t("button.cancel")}
        </Button>
        <Button
          isPrimary
          disabled={isDesktopApp}
          isLoading={isLoading || saveNotificationSettings.isLoading}
          onClick={onSave}
          className="sm:mt-0 grow-1 ml-4 w-full sm:w-40"
        >
          {t("button.save_changes")}
        </Button>
      </div>
    </>
  );
};

NotificationSettings.defaultProps = {
  onClose: () => {},
  isModal: false,
  isVisible: false,
};

NotificationSettings.propTypes = {
  onClose: PropTypes.func,
  isModal: PropTypes.bool,
  isVisible: PropTypes.bool,
};

export default NotificationSettings;
