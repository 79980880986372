import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useDeleteTemplate = () => {
  return useMutation((id) =>
    servicesUtils.postService(`/report_templates/${id}/delete/`)
  );
};

export default useDeleteTemplate;
