import classNames from "classnames";

const SelectedTag = ({ label, isGroup, onRemove }) => {
  return (
    <div
      data-tag
      className={classNames("!bg-gray-800 !rounded-full !text-gray-200", {
        "!bg-yellow-900": isGroup,
      })}
    >
      {label}
      <span data-tag-handle onClick={onRemove}>
        ×
      </span>
    </div>
  );
};

export default SelectedTag;
