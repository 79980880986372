import { Menu } from "@headlessui/react";
import classNames from "classnames";

const CategoryDropdown = ({
  dropdownCategory,
  categories,
  setDropdownCategory,
  labelMappings,
  isSearchClicked,
  setIsSearchClicked,
}) => {
  return (
    <div className="flex items-center justify-start text-[12px] bg-gray-100 dark:bg-gray-800">
      <div>
        <Menu>
          <Menu.Button>
            <div
              className={classNames(
                "flex items-center border border-gray-600 dark:border-gray-500 rounded-md pl-3 py-2 w-52 text-gray-800 dark:text-gray-200"
              )}
            >
              <div className="">
                {labelMappings[dropdownCategory] || "---Select Category---"}
              </div>
              <svg
                className="ml-auto mr-2"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 9L12 15L6 9"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </Menu.Button>
          <Menu.Items>
            <div className="cursor-pointer absolute z-40 w-52 border bg-gray-100 dark:bg-gray-800 space-y-3 py-2 mt-2 rounded-md border-gray-400">
              {Object.keys(categories).map((category) => (
                <Menu.Item key={category}>
                  <div
                    className={classNames(
                      "ui-active:bg-gray-200 dark:ui-active:bg-gray-700",
                      {
                        "bg-gray-300 dark:bg-gray-700 pointer-events-none":
                          dropdownCategory === category,
                      }
                    )}
                    onClick={() => setDropdownCategory(category)}
                  >
                    {labelMappings[category]}
                  </div>
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Menu>
      </div>

      <div
        className={classNames(
          "flex items-center justify-center border border-gray-600 rounded-md px-2 py-2 ml-auto cursor-pointer"
        )}
        onClick={() => setIsSearchClicked(!isSearchClicked)}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4 10C4 6.68629 6.68629 4 10 4C13.3137 4 16 6.68629 16 10C16 13.3137 13.3137 16 10 16C6.68629 16 4 13.3137 4 10ZM10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C11.8487 18 13.551 17.3729 14.9056 16.3199L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.3199 14.9056C17.3729 13.551 18 11.8487 18 10C18 5.58172 14.4183 2 10 2Z"
          />
        </svg>
      </div>
    </div>
  );
};

export default CategoryDropdown;
