import { useStoreState } from "pullstate";
import UserGroupManagement from ".";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import { useTranslation } from "react-i18next";

const UserGroupModal = () => {
  const { t } = useTranslation();
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isUserGroupOpen,
  }));

  const onCloseModal = () => {
    Store.update((s) => {
      s.isUserGroupOpen = false;
    });
  };

  return (
    <Modal
      title={t("setting_user")}
      onClose={onCloseModal}
      show={isVisible}
      className="flex flex-col w-full sm:w-7/12 lg:w-4/12 !h-auto max-h-[90%] sm:!max-h-[80%] overflow-hidden"
    >
      <UserGroupManagement isOpen={isVisible} />
    </Modal>
  );
};

export default UserGroupModal;
