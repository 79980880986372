import _defineProperty from "/vercel/path1/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
import axiosConnect from "./axiosConnect";
var X_CSRF = "x-csrftoken";

// simple wrapper around axios connect for simple fetch requests
// you will need to handle the errors from catch
function getService(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var paramsSerializer = arguments.length > 2 ? arguments[2] : undefined;
  var timeout = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 20000;
  var options = arguments.length > 4 ? arguments[4] : undefined;
  return axiosConnect.get(url, _objectSpread({
    params: params,
    paramsSerializer: paramsSerializer,
    timeout: timeout
  }, options)).then(function (response) {
    return response.data;
  });
}
function postService(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return axiosConnect.post(url, params).then(function (_ref) {
    var data = _ref.data;
    return data;
  });
}
function patchService(url, body) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return axiosConnect.patch(url, body, {
    params: params
  }).then(function (_ref2) {
    var data = _ref2.data;
    return data;
  });
}
function putService(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return axiosConnect.put(url, params).then(function (_ref3) {
    var data = _ref3.data;
    return data;
  });
}
function deleteService(url) {
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return axiosConnect["delete"](url, params).then(function (_ref4) {
    var data = _ref4.data;
    return data;
  });
}
export default {
  getService: getService,
  postService: postService,
  patchService: patchService,
  putService: putService,
  deleteService: deleteService,
  X_CSRF: X_CSRF
};