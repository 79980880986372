import { cloneDeep, get } from "lodash";

const Preview = ({ tags, labelMappings, tagsList, showOptionalTags }) => {
  const tags_ = cloneDeep(tags);
  const { tag1, tag2 } = tags_;
  const allTags = [tag1, tag2];

  if (showOptionalTags) {
    allTags.unshift({
      value: "Review required",
      category: "findings",
    });
  }

  return (
    <div className="text-left">
      <div className="text-md text-gray-600 dark:text-gray-300 font-semibold">
        PREVIEW
      </div>

      <div className="relative border border-gray-500 rounded-md pt-3 max-w-[325px] mt-3">
        <div className="grid grid-cols-12">
          <div className="col-span-2 flex items-start justify-center">
            <div className="bg-profile-pic-placeholder bg-no-repeat bg-center rounded bg-contain h-[30px] w-[30px]" />
          </div>
          <div className="col-span-10 space-y-3 pl-3 pr-2">
            <div>Patient Name</div>
            <div className="space-x-3 text-md">
              <span>Age/G</span>
              <span>ID: xxxxxxxxx</span>
            </div>
            <div className="flex flex-wrap gap-3 text-md">
              {!tag1.value && !tag2.value && "No tags configured"}
              {allTags.map((tag) => {
                const { category } = tag;
                const isValueObject =
                  category === "risk_groups" || category === "symptoms";
                if (isValueObject) {
                  get(tagsList, ["qxr", category, category]).map((choiceObj) => {
                    if (choiceObj.id === tag.value) {
                      tag.value = choiceObj.choice;
                    }
                  });
                }
                if (tag.value) {
                  return (
                    <span
                      key={tag.value}
                      className="bg-gray-100 dark:bg-gray-700 border border-gray-400 text-gray-900 dark:text-gray-100 rounded-full px-4 py-1 flex items-center"
                    >
                      {tag.category === "findings" && tag.value === "Review required" ? (
                        <div className="bg-yellow-400 mr-3 h-3 w-3 rounded-full" />
                      ) : (
                        <div className="bg-red-500 mr-3 h-3 w-3 rounded-full" />
                      )}
                      {labelMappings[tag.value] || tag.value}
                    </span>
                  );
                } else {
                  return "";
                }
              })}
            </div>
          </div>
          <div className="absolute top-3 right-3 flex items-start justify-end">
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              className="fill-gray-800"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 13.125C15 11.9513 15.9699 11 17.1667 11H22.7695C23.3451 11 23.8935 11.2239 24.2997 11.6226L27.3669 14.6291C27.7732 15.0275 28 15.5654 28 16.1299V25.875C28 27.0471 27.0284 28 25.8333 28H17.1667C15.9699 28 15 27.0471 15 25.875V13.125ZM26.375 25.875V16.3125H23.6667C23.0674 16.3125 22.5833 15.8377 22.5833 15.25V12.5938H17.1667C16.8674 12.5938 16.625 12.8315 16.625 13.125V25.875C16.625 26.1672 16.8674 26.4062 17.1667 26.4062H25.8333C26.1313 26.4062 26.375 26.1672 26.375 25.875Z"
                fill="#008280"
              />
              <rect
                x="14"
                y="19"
                width="7"
                height="9"
                className="fill-[#F6F7F7] dark:fill-gray-800"
              />
              <path
                d="M15.8193 26.8662C16.2451 27.292 16.9404 27.292 17.3662 26.8662L22.3662 21.8662C22.792 21.4404 22.792 20.7451 22.3662 20.3193C21.9404 19.8936 21.2451 19.8936 20.8193 20.3193L16.5928 24.5459L14.8662 22.8193C14.4404 22.3936 13.7451 22.3936 13.3193 22.8193C12.8936 23.2451 12.8936 23.9404 13.3193 24.3662L15.8193 26.8662Z"
                fill="#008280"
              />
            </svg>
          </div>
        </div>
        <div className="flex items-center justify-end text-[10px] text-gray-600 dark:text-gray-300 px-3 py-3">
          BIG Hospital | 10 Aug 2022, 7:55 pm
        </div>
      </div>
    </div>
  );
};

export default Preview;
