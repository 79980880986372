import { useEffect, useState } from "react";
import SubCategories from "./SubCategories";
import CategoryDropdown from "./CategoryDropdown";
import TagSearch from "./TagSearch";

const DEFAULT_CATEGORY = "findings";

const TagSettingsMenu = ({
  tagNo,
  studyName,
  userSettingsConf,
  setUserSettingsConf,
  labelMappings,
  tagsList,
}) => {
  const { category } = userSettingsConf.worklist.tags[studyName][tagNo];

  const categories = tagsList[studyName];
  const [dropdownCategory, setDropdownCategory] = useState(category);
  const subCategories = tagsList[studyName][dropdownCategory];

  const [isSearchClicked, setIsSearchClicked] = useState(false);

  useEffect(() => {
    setDropdownCategory(category);
  }, [userSettingsConf]);

  return (
    <div className="text-gray-800 dark:text-gray-200 px-5 flex flex-col h-full">
      {!isSearchClicked ? (
        <>
          <CategoryDropdown
            categories={categories}
            dropdownCategory={dropdownCategory}
            setDropdownCategory={setDropdownCategory}
            labelMappings={labelMappings}
            setIsSearchClicked={setIsSearchClicked}
            isSearchClicked={isSearchClicked}
          />
          <SubCategories
            studyName={studyName}
            userSettingsConf={userSettingsConf}
            dropdownCategory={dropdownCategory}
            subCategories={subCategories}
            setUserSettingsConf={setUserSettingsConf}
            tagNo={tagNo}
            labelMappings={labelMappings}
          />
        </>
      ) : (
        <TagSearch
          studyName={studyName}
          isSearchClicked={isSearchClicked}
          setIsSearchClicked={setIsSearchClicked}
          categories={categories}
          labelMappings={labelMappings}
          tagNo={tagNo}
          userSettingsConf={userSettingsConf}
          setUserSettingsConf={setUserSettingsConf}
        />
      )}
    </div>
  );
};

export default TagSettingsMenu;
