import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";

const useDeleteCondition = () => {
  return useMutation(({params, workspaceId}) =>
    servicesUtils.postService(`/workspaces/${workspaceId}/routing/delete/`, params)
  );
};

export default useDeleteCondition;
