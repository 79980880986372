import React, { useEffect, useState } from "react";
import Modal from "../common/Modal";
import Image from "next/image";
import Store from "../../utils/Store";
import { get, isEqual, pickBy } from "lodash";

import FormCreator from "../DynamicForm/FormCreator";
import useAddSignature from "./services/useAddSignature";
import useAuthService from "../../hooks/useAuthService";
import Toast from "../Toast";
import { useTranslation } from "react-i18next";
import { useStoreState } from "pullstate";
import { isNonDicomFile } from "../../utils";

const observations = [
  // {
  //   id: "study_details",
  //   choices: [
  //     {
  //       id: 0,
  //       choice: "Patient Name",
  //       disabled: true,
  //       defaultValue: true,
  //     },
  //     {
  //       id: 1,
  //       choice: "Time of study",
  //     },
  //     {
  //       id: 2,
  //       choice: "Age and gender",
  //       disabled: true,
  //       defaultValue: true,
  //     },
  //     {
  //       id: 3,
  //       choice: "Time of reporting",
  //     },
  //     {
  //       id: 4,
  //       choice: "Patient ID",
  //     },
  //     {
  //       id: 5,
  //       choice: "Referring doctor",
  //     },
  //     {
  //       id: 6,
  //       choice: "Location",
  //     },
  //   ],
  //   observation: "1. Study details",
  //   subObservation: "Select the dynamic study data to be included in all reports:",
  //   response_type: "multiple_choice",
  // },
  {
    id: "signature",
    observation: "1. Signature",
    response_type: "upload",
    inputClassName: "dark:!bg-gray-900 !h-[100px]",
    subObservation:
      "Upload an image with your signature against a white background (.jpg, .png)",
  },
  {
    id: "report_footer_line1",
    observation: "Line 1",
    subObservation:
      "Enter your name, designation, credentials or registration number below:",
    response_type: "text",
    placeholder: "Name, credentials etc.",
    inputClassName: "dark:!bg-gray-900 placeholder:text-left !text-left",
    labelClassName: "!mb-0 whitespace-nowrap",
    parentClassName: "w-full",
    isClinicalField: true,
    wrapperClassName: "flex space-x-4 items-center",
  },
  {
    id: "report_footer_line2",
    observation: "Line 2",
    response_type: "text",
    placeholder: "Role or title, registration number etc.",
    inputClassName: "dark:!bg-gray-900 placeholder:text-left !text-left",
    labelClassName: "!mb-0 whitespace-nowrap",
    parentClassName: "w-full",
    isClinicalField: true,
    wrapperClassName: "flex space-x-4 items-center",
  },
  {
    id: "disclaimer",
    observation: "Edit disclaimer that will appear in the footer (required field)",
    response_type: "textarea",
    className: "w-full bg-gray-200 dark:!bg-gray-900",
    title: "2. Disclaimer text",
    row: 8,
    required: true,
    inputClassName: "text-base dark:text-gray-200 !p-4",
  },
];

function SignatureModal({ isVisible, onClose }) {
  const { refetch: refetchProfile } = useAuthService(false);
  const { userInfo } = useStoreState(Store, (s) => ({
    userInfo: s.userInfo,
  }));

  const [formResponse, setFormResponse] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    setFormResponse({
      signature: get(userInfo, ["settings", "signature"], ""),
      report_footer_line1: get(
        userInfo,
        ["settings", "reporting_settings", "report_footer_line1"],
        ""
      ),
      report_footer_line2: get(
        userInfo,
        ["settings", "reporting_settings", "report_footer_line2"],
        ""
      ),
      disclaimer: get(userInfo, ["settings", "disclaimer"], ""),
    });
  }, [userInfo]);

  const onCloseModal = () => {
    onClose(true);
  };

  const OnSave = async (_response) => {
    setIsLoading(true);
    //to get only updated data
    const finData = pickBy(_response, (value, key) => {
      return formResponse.hasOwnProperty(key) && !isEqual(formResponse[key], value);
    });
    const fileType = get(finData, ["signature", "type"], "");
    const isValidImage = finData?.signature ? isNonDicomFile(fileType) : true;

    const response = await useAddSignature(finData, isValidImage);
    if (response?.status === 200) {
      refetchProfile();
      Toast.success(t("report_signature.toast.success"));
      onClose();
    } else if (response?.error) {
      Toast.error(t(response?.error));
    } else {
      Toast.error(t("report_signature.toast.failed"));
      onClose();
    }
    setIsLoading(false);
  };

  return (
    <>
      <Modal
        show={isVisible}
        onClose={onCloseModal}
        title={t("report_signature.title")}
        className="w-full sm:w-9/12 md:w-8/12 lg:w-6/12 xl:w-[45%]"
        closeOnClickAway
      >
        <div className="flex flex-col h-full">
          <div className="flex">
            <div className="border-r-2 border-gray-400 dark:border-gray-700 p-7">
              <Image
                src="/assets/images/report-format.png"
                alt="report-format"
                width={201}
                height={285}
              />
            </div>
            <div className="flex-1 text-left">
              <FormCreator
                formSchema={observations}
                response={formResponse}
                formName={"reporting_signature"}
                formId={""}
                onClose={onCloseModal}
                onSave={OnSave}
                // patientUid={get(router, ["query", "params", 0])}
                isIncomingForm={false}
                isSignatureForm
                wrapperClassName="bg-gray-200 dark:bg-transparent"
                containerClassName="!p-0 !px-6"
                isButtonLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SignatureModal;
