import { useMutation } from "react-query";
import servicesUtils from "../../../utils/servicesUtils";
import { omit } from "lodash";

const useEditTemplate = () => {
  return useMutation(({ payload, id }) =>
    servicesUtils.postService(`/report_templates/${id}/`, payload)
  );
};

export default useEditTemplate;
