import React from "react";
import Toggle from "../common/Toggle";
import { useTranslation } from "react-i18next";
import Checkbox from "../common/Checkbox";
import SubSection from "./SubSection";
import { BellAlertIcon, BellSlashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { cloneDeep, forEach, set } from "lodash";

function Section(props) {
  const { sectionDetails, isDisabled, onChangeSection, indexOf } = props;
  const { name, enabled, sound_alerts_enabled, selection, sub_section } = sectionDetails;
  const { t } = useTranslation();

  const onChangeSubSection = (data, index, isSkip) => {
    const _sectionDetails = cloneDeep(sectionDetails);
    if (isSkip) {
      set(_sectionDetails, ["sub_section", index], data);
    } else {
      if (_sectionDetails?.selection === "single") {
        forEach(_sectionDetails["sub_section"], (item) => {
          if (data.name === item.name) {
            item.enabled = true;
          } else {
            item.enabled = false;
          }
        });
      } else {
        set(_sectionDetails, ["sub_section", index], data);
      }
    }
    onChangeSection(_sectionDetails, indexOf);
  };

  return (
    <>
      <div
        className={classNames(
          "flex justify-between py-3 px-6 sm:px-0 bg-gray-800 sm:bg-transparent",
          {
            "mb-4 sm:mb-0": sound_alerts_enabled === undefined,
          }
        )}
      >
        <div
          className={classNames(
            "flex text-gray-800 dark:text-white text-base font-bold",
            { "opacity-50": isDisabled }
          )}
        >
          {t(name)}
        </div>
        <div className="flex items-center justify-center h-6">
          <Toggle
            value={enabled}
            disabled={isDisabled}
            onChange={() => {
              const fin_data = { ...sectionDetails, enabled: !enabled };
              if (name === "notify_emergency") {
                fin_data.sound_alerts_enabled = !enabled;
              }
              onChangeSection(fin_data, indexOf);
            }}
          />
        </div>
      </div>
      {sound_alerts_enabled !== undefined && (
        <div
          className={classNames(
            "flex justify-between border-y border-gray-700 py-3 mb-5 px-6 md:px-0",
            { "border-b-0": sectionDetails?.name === "notify_emergency" }
          )}
        >
          <Checkbox
            id={`sound_alerts_enabled_${name}`}
            className=""
            disabled={(!enabled && name !== "notify_emergency") || isDisabled}
            checked={sound_alerts_enabled}
            onChange={(val) => {
              const fin_data = { ...sectionDetails, sound_alerts_enabled: val };

              if (name === "notify_emergency") {
                fin_data.enabled = val;
              }
              onChangeSection(fin_data, indexOf);
            }}
            label={t("sound_alerts_enabled")}
            labelClassName="!text-md"
          />
          {sound_alerts_enabled ? (
            <BellAlertIcon
              className={classNames("w-[20px] h-[20px] dark:text-gray-100", {
                "opacity-50": !enabled || isDisabled,
              })}
            />
          ) : (
            <BellSlashIcon
              className={classNames("w-[20px] h-[20px] dark:text-gray-100", {
                "opacity-50": !enabled || isDisabled,
              })}
            />
          )}
        </div>
      )}
      {sub_section?.map((detail, index) => (
        <div key={index}>
          <SubSection
            indexOf={index}
            subSectionDetails={detail}
            isDisabled={isDisabled || !enabled}
            selectionType={selection}
            onChangeSubSection={onChangeSubSection}
          />
        </div>
      ))}
    </>
  );
}

export default Section;
